<template>
  <div>
    <Table
      :tableHeader="tableHeader"
      :data="reportData"
      :hasActions="false"
      :isLoading="isLoading"
      :paginationSettings="paginationSettings"
      @onPageChange="onPageChange($event)"
    >
      <template #tableFilters>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search Drivers"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="onKeywordSearch()"
          />
        </div>
      </template>
      <template #callToActionTop>
        <button
          class="btn btn-primary flex items-center"
          @click.prevent="onGetReport"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Get Report
        </button>
      </template>
    </Table>
    <Modal
      ref="modal"
      :title="modal.title"
      :size="modal.size"
      :height="modal.height"
    >
      <div v-if="modal.content === 'email'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm v-model="sendReportModel" @submit="onSubmit">
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="recipientEmail"
                  label="Email address"
                  validation="required|email"
                  @input="handleEmailCasing($event)"
                />
                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="btn btn-primary w-full mt-5"
                  :disabled="
                    !sendReportModel.recipientEmail || sendReportloading
                  "
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
      <div v-if="modal.content === 'linked-businesses'">
        <Table
          :isLoading="false"
          :tableHeader="linkedBusinessesHeader"
          :data="linkedBusinesses"
          :hasActions="false"
          :paginationSettings="{
            page: 1,
            totalPages: 1,
            totalRecords: linkedBusinesses.length,
          }"
        >
        </Table>
      </div>
    </Modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { Table, Modal } from "@/components/commons";
import {
  REPORT_NAMES,
  SEARCH_DEBOUNCE_DELAY,
} from "../../../_helper/constants";
import { cleanDateFormat } from "../../../_helper/index";
import { formatPaginationSettings } from "@/_helper";
import _ from "lodash";
export default {
  name: "DriverContractsReport",
  components: { Table, Modal },
  data() {
    return {
      selectedDateRange: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],

      tableHeader: [
        "Driver Name",
        "Driver Email",
        "Created Date",
        "Linked Businesses",
      ],
      linkedBusinessesHeader: [
        "Business Name",
        "Business Email",
        "Depot",
        "Contact Name",
        "Contact Phone No.",
        "Self Created",
        "Contract Start Date",
      ],
      reportData: [],
      isLoading: false,
      paginationSettings: {
        page: 1,
        totalPages: 1,
        totalRecords: 0,
        visiblePageItemCount: 10,
      },
      filter: {
        limit: 10,
        search: "",
      },
      sendReportModel: { recipientEmail: null },
      sendReportloading: false,
      modal: {
        title: "",
        content: null,
        height: "50vh",
        size: "lg",
      },
      linkedBusinesses: [],
    };
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedOnSearch,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
  async mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch(page = 1) {
      this.isLoading = true;
      const query = {
        skip: page * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };
      const result = await this.$store.dispatch(
        "report/getDriverContractsReport",
        query
      );
      this.paginationSettings = formatPaginationSettings(result?.resultsMeta);
      this.reportData = this.formateTableData(result.data);

      this.isLoading = false;
    },
    formateTableData(data) {
      return data.map((row) => {
        return [
          {
            id: row._id,
            name: row.driverName || "N/A",
          },
          {
            id: row._id,
            name: row.driverEmail || "N/A",
          },
          {
            id: row._id,
            name: cleanDateFormat(row.createdAt) || "N/A",
          },
          {
            id: row._id,
            name: "View",
            itemType: "function",
            metadata: {
              businesses: row.businesses,
            },
            onClick: ({ businesses }) => {
              this.onOpenLinkedBusinesses(businesses);
            },
          },
        ];
      });
    },
    onGetReport() {
      this.modal.size = "lg";
      this.modal.content = "email";
      this.modal.title = "Send Report To Email";
      this.$refs.modal.openModal();
    },
    async onSubmit() {
      this.sendReportloading = true;
      const payload = {
        reportType: REPORT_NAMES.DRIVER_CONTRACTS_REPORT,
        recipientEmail: this.sendReportModel.recipientEmail,
      };

      try {
        await this.$store.dispatch("report/generateReport", payload);
        this.toast(
          "success",
          `An email will be sent to ${payload.recipientEmail} shortly`
        );
      } catch (error) {
        this.toast("error", "Something went wrong!");
      }
      this.sendReportloading = false;
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    handleEmailCasing(value) {
      this.sendReportModel.recipientEmail = value.toLowerCase();
    },

    async onPageChange(event) {
      if (event.page) {
        this.onSearch(event.page);
      }
    },

    onOpenLinkedBusinesses(businesses) {
      this.$refs.modal.openModal();
      this.modal.content = "linked-businesses";
      this.modal.title = "Linked Businesses";
      this.modal.size = "4xl";
      this.linkedBusinesses = businesses.map((business, index) => {
        const {
          businessName,
          businessEmail,
          businessContactName,
          businessContactPhoneNumber,
          startDate,
          isExternal,
          depotName,
        } = business;
        return [
          {
            id: index,
            name: businessName,
          },
          {
            id: index,
            name: businessEmail,
          },
          {
            id: index,
            name: depotName ? depotName : "N/A",
          },
          {
            id: index,
            name: businessContactName,
          },
          {
            id: index,
            name: businessContactPhoneNumber,
          },
          {
            id: index,
            name: isExternal,
          },
          {
            id: index,
            name: cleanDateFormat(startDate) || "N/A",
          },
        ];
      });
    },

    onKeywordSearch() {
      this.stoppedTyping();
    },
    debouncedOnSearch() {
      this.onSearch();
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style scoped lang="scss"></style>
