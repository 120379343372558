<template>
  <div>
    <div
      class="w-full overflow-x-auto overflow-y-hidden driver-graph"
      v-html="data"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.driver-graph::v-deep {
  padding-bottom: 15px;
  .graphical-summary {
    margin-left: 0px;
  }
  .g-bg-blue {
    @apply bg-blue;
  }
}
</style>

<script>
export default {
  name: "GraphicalSummary",
  props: {
    data: String,
  },
};
</script>
