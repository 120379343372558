import { API } from "@/api/config";
import { ObjectToQueryString } from "@/_helper";

export default new (class Notification {
  // Notifications
  getAllUnAcknowledgedNotifications() {
    return API.ACCOUNTS.get(`/action/me/init?showCompleted=0`);
  }

  getAllNotifications(query = null) {
    let url = `/action/me?showCompleted=false`;
    if (query) query = "&" + ObjectToQueryString(query);
    return API.ACCOUNTS.get(url);
  }

  getNotificationByID(id) {
    return API.ACCOUNTS.get(`/action/${id}`);
  }

  updateItemStatus(payload) {
    return API.ACCOUNTS.patch(`/action/item/${payload.id}`, payload);
  }

  toggleNotification(type, checked, webProfileUid) {
    return API.ACCOUNTS.post(`/web-profile/toggle-settings/${webProfileUid}`, {
      notificationType: type,
      checked,
    });
  }
})();
