<template>
  <div
    :data-type="context.type"
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
  >
    <list-select
      ref="selectRef"
      :options="options"
      :context="context"
      :isLoading="isLoading"
      :hasPrevPage="hasPrevPage"
      :hasNextPage="hasNextPage"
      noResultText="No certifications found."
      @prevPage="onPrevPage"
      @nextPage="onNextPage"
      @search="onSearch"
    >
      <template slot="option" slot-scope="option">
        <div class="text-sm">
          <h3 class="m-0 font-semibold">{{ option.certNumber }}</h3>
          <em class="text-xs">{{ option.type }}</em>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected text-sm">
          {{ option.certNumber }}
        </div>
      </template>
    </list-select>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { formatPaginationSettings } from "@/_helper";
import ListSelect from "@/components/commons/ui/list-select/ListSelect";

const CertSelect = {
  name: "CertSelect",
  components: {
    ListSelect,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    certificates: [],
    options: [],
    isLoading: false,
    hasPrevPage: false,
    hasNextPage: false,
    filter: {
      limit: 10,
      status: "all",
      search: "",
    },
    paginationSettings: {
      page: 1,
      totalPages: 5,
      totalRecords: 50,
      visiblePageItemCount: 3,
    },
    debouncedOnSearch: () => {},
  }),
  created() {
    this.debouncedOnSearch = debounce(async (search) => {
      this.filter.search = search;
      this.options = [];
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      const query = {
        skip:
          this.paginationSettings.page * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      const accreditations = await this.$store.dispatch(
        `accreditation/getAllAccreditation`,
        { userData: me, pagination: query }
      );
      if (accreditations) {
        if (accreditations.data.data.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            accreditations.data.data.resultsMeta
          );
          this.hasNextPage =
            this.paginationSettings.page < this.paginationSettings.totalPages;
          this.hasPrevPage = this.paginationSettings.page > 1;
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        this.certificates = accreditations.data.data.results;
        this.options = this.certificates;
      }
      this.isLoading = false;
    }, 500);
  },
  mounted() {
    this.$refs.selectRef.onSearch();
  },
  methods: {
    async onSearch(search = "") {
      this.debouncedOnSearch(search);
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        this.paginationSettings.page = this.paginationSettings.page - 1;
        this.onSearch();
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
        this.paginationSettings.page = this.paginationSettings.page + 1;
        this.onSearch();
      }
    },
  },
};

export const VueFormulateCertSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      CertSelect,
    },
    library: {
      "cert-select": {
        classification: "select",
        component: "CertSelect",
      },
    },
  });
};

export default CertSelect;
</script>
