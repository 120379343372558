<template>
  <aside class="px-2">
    <div class="overflow-y-auto pt-4 pb-1 px-3 rounded-md flex flex-col">
      <p class="pl-2 pb-4 text-sm" :style="{color: theme.primaryColor}">Components</p>
      <hr/>
    </div>
    <div class="p-2 mt-2">
      <draggable
        tag="ul"
        :list="basicComponents"
        :group="{ name: 'controls', pull: 'clone', put: false }"
        :sort="false"
        class="space-y-2 px-2"
        :clone="onClone"
      >
        <li v-for="(item, index) in basicComponents" :key="index">
          <a
            class="bg-slate-100 rounded-lg flex items-center p-2 text-xs cursor-move border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
            :style="{color: theme.primaryColor}"
          >
            <font-awesome-icon :icon="item.icon" class="mx-1" size="sm" />
            <span>&nbsp; {{ item.label }}</span>
          </a>
        </li>
      </draggable>
    </div>
    <div class="overflow-y-auto pt-4 pb-1 px-3 rounded-md flex flex-col">
      <p class="text-xs" :style="{color: theme.primaryColor}">Advanced Components</p>
    </div>
    <div class="p-2">
      <draggable
        tag="ul"
        :list="advancedComponents"
        :group="{ name: 'controls', pull: 'clone', put: false }"
        :sort="false"
        class="space-y-2 px-2"
        :clone="onClone"
      >
        <li v-for="(item, index) in advancedComponents" :key="index">
          <a
            class="bg-slate-100 rounded-lg flex items-center p-2 text-xs cursor-move border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
            :style="{color: theme.primaryColor}"
          >
            <font-awesome-icon :icon="['fas', item.icon]" class="mx-1" size="sm" />
            <span>&nbsp; {{ item.label }}</span>
          </a>
        </li>
      </draggable>
    </div>
  </aside>
</template>
<script>
import Draggable from 'vuedraggable'
import { HELPER } from '@/api/helper'
import { mapGetters } from 'vuex'
import { servicesList } from '@/api/services'

export default {
  components: {
    Draggable
  },
  data: () => ({
    basicComponents: [
      {
        id: 'plain-text',
        component: 'plain-text',
        name: 'label',
        label: 'Label',
        content: 'This is a plain text',
        alignment: 'left',
        size: 'base',
        weight: 'normal',
        icon: 'font'
      },
      {
        id: 'text-control',
        component: 'text-control',
        name: 'textbox',
        label: 'Input Field',
        icon: 'terminal'
      },
      {
        id: 'number-control',
        component: 'number-control',
        name: 'number',
        label: 'Number Field',
        icon: 'calculator'
      },
      {
        id: 'email-control',
        component: 'email-control',
        name: 'email',
        label: 'Email Field',
        icon: 'at',
      },
      {
        id: 'password-control',
        component: 'password-control',
        name: 'password',
        label: 'Password Field',
        icon: 'lock'
      },
      {
        id: 'textarea-control',
        component: 'textarea-control',
        name: 'textarea',
        label: 'Textarea Field',
        icon: 'font'
      },
      {
        id: 'select-control',
        component: 'select-control',
        name: 'selectBox',
        label: 'Select Box',
        icon: 'list'
      },
      {
        id: 'datepicker-control',
        component: 'datepicker-control',
        name: 'datePicker',
        label: 'Date Picker',
        icon: 'calendar-plus'
      },
      {
        id: 'timepicker-control',
        component: 'timepicker-control',
        name: 'timePicker',
        label: 'Time Picker',
        icon: 'clock'
      },
      {
        id: 'checkbox-control',
        component: 'checkbox-control',
        name: 'listCheckbox',
        label: 'List Checkbox',
        icon: 'check-square'
      },
      {
        id: 'radiobutton-control',
        component: 'radiobutton-control',
        name: 'radioButton',
        label: 'Radio Button',
        icon: 'circle'
      },
      {
        id: 'switch-control',
        component: 'switch-control',
        name: 'switch',
        label: 'Switch',
        icon: 'toggle-off'
      }
    ],
    advancedComponents: [
      {
        id: 'vehicle-select-control',
        component: 'vehicle-select-control',
        name: 'VehicleSelect',
        label: 'Select Vehicle',
        icon: 'truck'
      },
      {
        id: 'file-control',
        component: 'file-control',
        name: 'document',
        label: 'Document',
        icon: 'arrow-up'
      },
      {
        id: 'documentlink-control',
        component: 'documentlink-control',
        name: 'docLink',
        label: 'Doc Link',
        icon: 'link'
      },
      {
        id: 'image-control',
        component: 'image-control',
        name: 'image',
        label: 'Image',
        icon: 'image'
      },
      {
        id: 'signaturepad-control',
        component: 'signaturepad-control',
        name: 'signaturePad',
        label: 'Signature Pad',
        icon: 'signature'
      }
    ]
  }),

  methods: {
    onClone(e) {
      let formControl = HELPER.cloneDeep(e)
      formControl.uniqueId = 'control-' + HELPER.getUUIDv4()
      formControl.type = 'text'
      formControl.placeholder = ''
      formControl.help = ''
      formControl.validation = []
      formControl.validationName = ''
      formControl.selected = true
      formControl.required = ''

      if (
        formControl.component === 'select-control' ||
        formControl.component === 'radiobutton-control' ||
        formControl.component === 'checkbox-control'
      ) {
        formControl.value = 'first'
        formControl.options = [
          {
            value: 'first',
            label: 'First',
            isIssue: false
          },
          {
            value: 'second',
            label: 'Second',
            isIssue: false
          },
          {
            value: 'third',
            label: 'Third',
            isIssue: false
          }
        ]
        formControl.options.map((e) => (e.uniqueId = HELPER.getUUIDv4()))

      } else if (formControl.component === 'image-control') {
        formControl.max_quantity = 1
        formControl.with_camera_option = true

      } else if (formControl.component === 'file-control') {
        formControl.validation = ['mime:application/pdf']
      } else if (formControl.component === 'documentlink-control') {
        formControl.value = 'https://google.com'
      } else if (formControl.component === 'vehicle-select-control') {
        let environment = process.env.VUE_APP_ENV

        Object.keys(servicesList).forEach(async mservices => {
            let url;
            if(environment === 'development') {
                url = servicesList[mservices].SANDBOX_URL
            } else if (environment === 'staging') {
                url = servicesList[mservices].STAGING_URL
            } else if (environment === 'production') {
                url = servicesList[mservices].PRODUCTION_URL
            } else if (environment === 'local') {
                url = servicesList[mservices].LOCAL_URL
            }
            formControl.web_source_api = url
        })
        formControl.mobile_source_api = ''
        formControl.mobile_option_vehicle_id = ''
        formControl.mobile_option_vehicle_value = ''
        formControl.web_option_vehicle_id = ''
        formControl.web_option_vehicle_value = ''
      }
      return formControl
    }
  },
  computed:{
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>
