<template>
  <div
    :data-type="context.type"
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
  >
    <list-select
      ref="selectRef"
      :options="fleetConnections"
      :context="context"
      :isLoading="isLoading"
      :hasPrevPage="hasPrevPage"
      :hasNextPage="hasNextPage"
      noResultText="No fleet connections found."
      @prevPage="onPrevPage"
      @nextPage="onNextPage"
      @search="onSearch"
    >
      <template slot="option" slot-scope="option">
        <div class="text-sm">
          <h3 class="m-0 font-semibold">
            {{ option.name }} ({{ option.provider }})
          </h3>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected text-sm">
          {{ option.name }} ({{ option.provider }})
        </div>
      </template>
    </list-select>
  </div>
</template>
<script>
import _ from "lodash";
import { formatPaginationSettings } from "@/_helper";
import ListSelect from "@/components/commons/ui/list-select/ListSelect";

const FleetConnectionSelect = {
  name: "FleetConnectionSelect",
  components: {
    ListSelect,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    fleetConnections: [],
    isLoading: false,
    hasPrevPage: false,
    hasNextPage: false,
    filter: {
      limit: 10,
      search: "",
    },
    paginationSettings: {
      page: 1,
      totalPages: 5,
      totalRecords: 50,
      visiblePageItemCount: 3,
    },
    debouncedOnSearch: () => {},
  }),

  created() {
    this.debouncedOnSearch = _.debounce(async (search) => {
      const me = this.$store.getters[`account/me`];
      this.filter.search = search;
      this.fleetConnections = [];
      this.isLoading = true;
      const query = {
        businessId: me.businessId ? me.businessId : me?.business?._id,
        pagination: {
          skip:
            this.paginationSettings.page * this.filter.limit -
            this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {
            ...(this.context.attributes.geotab ? { provider: "geotab" } : {}),
          },
        },
      };

      const result = await this.$store.dispatch(
        "business/getFleetConnections",
        query
      );

      this.paginationSettings = {
        ...this.paginationSettings,
        ...formatPaginationSettings(result.resultsMeta),
      };
      this.hasNextPage =
        this.paginationSettings.page < this.paginationSettings.totalPages;
      this.hasPrevPage = this.paginationSettings.page > 1;

      this.fleetConnections = result.data;
      this.isLoading = false;
    }, 500);
  },
  mounted() {
    this.$refs.selectRef.onSearch();
  },
  methods: {
    async onSearch(search = "") {
      this.debouncedOnSearch(search);
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        this.paginationSettings.page = this.paginationSettings.page - 1;
        this.onSearch();
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
        this.paginationSettings.page = this.paginationSettings.page + 1;
        this.onSearch();
      }
    },
  },
};

export const VueFormulateFleetConnectionSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      FleetConnectionSelect,
    },
    library: {
      "fleet-connection-select": {
        classification: "select",
        component: "FleetConnectionSelect",
      },
    },
  });
};

export default FleetConnectionSelect;
</script>
