<template>
  <div class="table-wrapper h-full">
    <slot name="tableDescription" />
    <div class="my-2 pl-2 flex sm:flex-row flex-col items-center">
      <div class="flex mr-auto items-center">
        <slot name="tableFilters" />
        <div v-if="hasMultiFilter && hasColumnFilters">
          <button
            class="btn btn-primary ml-2"
            :style="{ backgroundColor: theme.secondaryColor }"
            title="Clear Filters"
            @click.prevent="clearFilters"
          >
            <font-awesome-icon icon="eraser" class="my-auto" /> Clear filters
          </button>
        </div>
      </div>

      <!-- Top call to action button(s) -->
      <div class="px-2 flex ml-auto items-center">
        <slot name="callToActionTop" />
        <div v-if="hasExportPdf">
          <button
            class="btn btn-primary ml-1"
            :style="{ backgroundColor: theme.secondaryColor }"
            title="Export as PDF"
            @click.prevent="exportAsPDF"
          >
            <font-awesome-icon icon="eraser" class="my-auto" /> Export as PDF
          </button>
        </div>
      </div>
    </div>
    <div class="pl-2 block">
      <slot name="informationalPanel" />
    </div>
    <div class="flex ml-auto px-2">
      <slot name="buttons" />
    </div>
    <div class="px-2 sm:px-2 py-4 overflow-x-auto flex-1">
      <div class="inline-block min-w-full shadow rounded-lg flex-1">
        <table class="min-w-full leading-normal table-auto overflow-x-auto">
          <thead v-if="!hasMultiFilter">
            <tr>
              <th
                v-if="hasCheckbox"
                scope="col"
                class="p-4"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                <!-- <div class="flex items-center">
                    <input type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded" @change="selectAll()">
                    <label class="sr-only">checkbox</label>
                </div> -->
              </th>
              <th
                v-for="(header, index) in tableHeader"
                :key="index"
                :class="[
                  header.toUpperCase() === 'TRAINING'
                    ? trainingHeaderClass
                    : defaultHeaderClass,
                ]"
                class="group"
                :style="{ backgroundColor: theme.accentColor }"
              >
                {{ header }}
              </th>
              <th
                v-if="hasActions"
                class="px-5 py-4 bg-gray-100 text-center text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.accentColor }"
              >
                Actions
              </th>
            </tr>
          </thead>
          <thead v-else>
            <tr>
              <th
                v-if="hasCheckbox"
                scope="col"
                class="p-4"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                <!-- <div class="flex items-center">
                    <input type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded" @change="selectAll()">
                    <label class="sr-only">checkbox</label>
                </div> -->
              </th>
              <th
                v-for="(header, index) in tableHeader"
                :key="index"
                :class="[
                  header.name.toUpperCase() === 'TRAINING'
                    ? trainingHeaderClass
                    : defaultHeaderClass,
                ]"
                class="group"
                :style="{ backgroundColor: theme.accentColor }"
              >
              <div class="flex justify-between items-center">
                <span>{{ header.name }}</span>
                <FilterDropdown button-class="opacity-0 group-hover:opacity-100 transition hover:text-orange-primary text-white" v-if="header.isFilterable">
                  <template v-slot:button>
                    <font-awesome-icon :icon="['fas', 'bars']"/>
                  </template>
                  <template v-slot:menu>
                    <div v-if="header.type === 'text'">
                      <FormulateInput
                        :options="textOptions"
                        type="select"
                        v-model="columnFilters[header.fieldName].operator"
                        @change="handleSearchKeyDown"
                      />
                      <FormulateInput
                        type="text"
                        v-model="columnFilters[header.fieldName].value"
                        @keydown="handleSearchKeyDown"
                      />
                    </div>
                    <div v-if="header.type === 'boolean'">
                      <FormulateInput
                        :options="{ boolEqual: 'Equals'}"
                        type="select"
                        v-model="columnFilters[header.fieldName].operator"
                        @change="handleSearchKeyDown"
                      />
                      <FormulateInput
                        :options="header.filterOptions"
                        type="select"
                        v-model="columnFilters[header.fieldName].value"
                        @change="handleSearchKeyDown"
                      />
                    </div>
                    <div v-if="header.type === 'number'">
                      <FormulateInput
                        :options="numberOptions"
                        type="select"
                        v-model="columnFilters[header.fieldName].operator"
                        @change="handleSearchKeyDown"
                      />
                      <FormulateInput
                        type="number"
                        validation="number"
                        v-model="columnFilters[header.fieldName].value"
                        @keydown="handleSearchKeyDown"
                      />
                    </div>
                    <div v-if="header.type === 'date'">
                      <FormulateInput
                        :options="dateOptions"
                        type="select"
                        v-model="columnFilters[header.fieldName].operator"
                        @change="handleSearchKeyDown"
                      />
                      <div v-if="columnFilters[header.fieldName].operator === 'dateRange'" class="flex flex-col space-y-1">
                        <FormulateInput
                          type="date"
                          v-model="columnFilters[header.fieldName].value"
                          @change="handleSearchKeyDown"
                        />
                        <FormulateInput
                          type="date"
                          v-model="columnFilters[header.fieldName].value2"
                          @change="handleSearchKeyDown"
                          :min="columnFilters[header.fieldName].value"
                        />
                      </div>
                      <FormulateInput
                        v-else
                        type="date"
                        v-model="columnFilters[header.fieldName].value"
                        @change="handleSearchKeyDown"
                      />
                    </div>
                  </template>
                </FilterDropdown>
              </div>
              </th>
              <th
                v-if="hasActions"
                class="px-5 py-4 bg-gray-100 text-center text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.accentColor }"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="isLoading"
              class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default"
            >
              <td
                :colspan="
                  hasActions ? tableHeader.length + 1 : tableHeader.length
                "
                class="text-center p-4 loading"
              >
                <ui-spinner class="mx-auto"></ui-spinner>
                <span v-if="loadingText">{{ loadingText }}</span>
              </td>
            </tr>
            <tr
              v-else
              v-for="(datas, index) in data"
              :key="index"
              class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default"
            >
              <!-- Actions/Options -->
              <slot name="callToActionTableFieldBefore" />
              <td
                v-for="(dataItems, index) in datas"
                :key="index"
                class="px-5 py-2"
              >
                <div
                  v-if="dataItems.itemType === 'toggle'"
                  class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                >
                  <input
                    type="checkbox"
                    :checked="dataItems.name"
                    name="toggle"
                    :disabled="!dataItems.editable"
                    :id="`toggle-${dataItems.id}${dataItems?.key}`"
                    class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                  />
                  <label
                    for="toggle"
                    class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>
                <div
                  class="flex justify-center w-full items-center"
                  v-else-if="dataItems.itemType === 'checkbox'"
                >
                  <input
                    v-model="dataItems.checked"
                    type="checkbox"
                    @change="callDataChange()"
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label class="sr-only">checkbox</label>
                </div>

                <div
                  v-else-if="
                    dataItems.itemType === 'label' && dataItems.isStatus
                  "
                >
                  <p v-if="dataItems.name" class="relative">
                    <font-awesome-icon
                      icon="circle"
                      class="absolute top-1 text-green-500 text-xs border-2 border-white rounded-full"
                    />
                    <span class="pl-4 text-black-primary whitespace-no-wrap"
                      >Active</span
                    >
                  </p>
                  <p v-else class="relative">
                    <font-awesome-icon
                      icon="circle"
                      class="absolute top-1 text-yellow-500 text-xs border-2 border-white rounded-full"
                    />
                    <span class="pl-4 text-black-primary whitespace-no-wrap"
                      >Inactive</span
                    >
                  </p>
                </div>

                <div
                  v-else-if="
                    dataItems.itemType === 'name' && dataItems.hasImage
                  "
                  class="flex items-center"
                >
                  <div class="flex-shrink-0 w-10 h-10">
                    <div
                      class="w-full h-full rounded-full overflow-hidden flex bg-gray-200"
                    >
                      <font-awesome-icon
                        icon="user"
                        class="text-xl m-auto text-gray-600"
                      />
                    </div>
                  </div>
                  <div class="ml-3">
                    <p
                      v-if="dataItems.hasLink"
                      class="text-black-primary whitespace-no-wrap"
                    >
                      <a href="">
                        {{ dataItems.name }}
                      </a>
                    </p>
                    <p v-else class="text-black-primary whitespace-no-wrap">
                      {{ dataItems.name }}
                    </p>
                  </div>
                </div>

                <div v-else-if="dataItems.itemType === 'link'">
                  <a
                    :href="dataItems.link"
                    target="_blank"
                    class="bg-white border border-orange-primary hover:bg-orange-primary text-orange-primary hover:text-white text-xs font-medium py-1 px-2 rounded-full"
                    >{{ dataItems.name }}</a
                  >
                </div>

                <div v-else-if="dataItems.itemType === 'badge'">
                  <span
                    v-if="dataItems.badgeType == 'success'"
                    class="badge badge-success"
                    >{{ dataItems.name }}</span
                  >
                  <span
                    v-else-if="dataItems.badgeType == 'error'"
                    class="badge badge-error"
                    >{{ dataItems.name }}</span
                  >
                  <span
                    v-else-if="dataItems.badgeType == 'warning'"
                    class="badge badge-warning"
                    >{{ dataItems.name }}</span
                  >
                  <span
                    v-else-if="dataItems.badgeType == 'info'"
                    class="badge badge-info"
                    >{{ dataItems.name }}</span
                  >
                </div>

                <p
                  v-else-if="dataItems.itemType === 'function'"
                  class="text-black-primary whitespace-no-wrap underline cursor-pointer"
                  @click="
                    dataItems.onClick(
                      dataItems.metadata
                    )
                  "
                >
                  {{ dataItems.name }}
                </p>

                <p v-else class="text-black-primary whitespace-no-wrap">
                  {{ dataItems.name }}
                </p>
              </td>
              <!-- Actions/Options -->
              <slot :data="datas[0]" />
            </tr>
          </tbody>
        </table>
        <div v-if="!data.length && !isLoading" class="bg-white text-center p-4">
          {{ defaultMessage? defaultMessage: 'No Record Found' }}
        </div>
        <Pagination
          v-if="data.length"
          :is-loading="isLoading"
          :total-pages="paginationSettings.totalPages"
          :current-page="paginationSettings.page"
          :total-records="paginationSettings.totalRecords"
          :visible-page-item-count="paginationSettings.visiblePageItemCount"
          :per-page="data.length"
          :pagination-description="paginationSettings.paginationDescription"
          @pagechanged="onPageChange"
        />
      </div>
    </div>
    <Modal
      ref="modal"
      :title="modal.modalTitle"
      size="3xl"
      :height="modal.modalHeight"
      @closeModal="closeModal()"
    >
      <div v-if="modal.modalContent == 'exportPDF'">
        <div class="">
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Pagination, Modal } from "@/components/commons";
import { mapGetters } from "vuex";
import FilterDropdown from "@/components/commons/ui/FilterDropdown.vue";
import _ from "lodash";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import moment from 'moment';
export default {
  components: { Pagination, FilterDropdown, Modal },
  emits: ["onPageChange"],
  props: {
    tableHeader: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    paginationSettings: {
      type: Object,
      default: function () {
        return {
          page: 1,
          totalPages: 1,
          totalRecords: 1,
          visiblePageItemCount: 1,
          paginationDescription: null
        };
      },
    },
    data: {
      type: Array[
        {
          id: Number,
          name: String,
          itemType: String,
          hasImage: Boolean,
          imageUrl: String,
          hasLink: Boolean,
          checked: Boolean,
        }
      ],
      default: function () {
        return [];
      },
    },
    hasActions: {
      type: Boolean,
      default: true,
    },
    hasMultiFilter: {
      type: Boolean,
      default: false
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: null,
    },
    hasExportPdf: {
      type: Boolean,
      default: false
    },
    defaultMessage: {
      type: String
    }
  },
  data() {
    return {
      isFilterMenuVisible: [],
      seachKeyword: "",
      isSelectAll: false,
      defaultHeaderClass:
        "px-5 py-4 bg-gray-100 text-left text-xs font-semibold text-white uppercase tracking-wider",
      trainingHeaderClass:
        "px-5 py-4 bg-gray-100 text-center text-xs font-semibold text-white uppercase tracking-wider",
      textOptions: { 
        contains: 'Contains', 
        notContains: 'Not contains', 
        equal: 'Equals', 
        notEqual: 'Not equal', 
        startsWith: 'Starts with',
        endsWith: 'Ends with'
      },
      numberOptions: {
        numberEqual: 'Equals',
        numberNotEqual: 'Not equal',
        lessThan: 'Less than',
        lessThanEqual: 'Less than or equal',
        greaterThan: 'Greater than',
        greaterThanEqual: 'Greater than or equal',
      },
      dateOptions: {
        dateEqual: 'Equals',
        dateNotEqual: 'Not equal',
        dateGreaterThan: 'Greater than',
        dateGreaterThanEqual: 'Greater than or equal',
        dateLessThan: 'Less than',
        dateLessThanEqual: 'Less than or equal',
        dateRange: 'In Between'
      },
      columnFilters: {},
      filteredHeader: [],
      hasColumnFilters: false,
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: "80vh",
      },
    };
  },
  async mounted () {
    this.isFilterMenuVisible = await new Array(this.tableHeader.length).fill(false);
    if(this.hasMultiFilter) {
      await this.initializeColumnFilters();
    }
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true
      }
    )
  },
  methods: {
    async initializeColumnFilters() {
      this.filteredHeader = [...this.tableHeader]
      this.filteredHeader.forEach(item => {
        if(item.type === 'date') {
          this.$set(this.columnFilters, String(item.fieldName), {
              value: '',
              value2: '',
              operator: 'dateEqual'
          });
        } else if(item.type === 'number') {
          this.$set(this.columnFilters, String(item.fieldName), {
              value: '',
              operator: 'numberEqual'
          });
        } else if(item.type === 'boolean') {
          this.$set(this.columnFilters, String(item.fieldName), {
              value: '',
              operator: 'boolEqual'
          });
        } else {
          this.$set(this.columnFilters, String(item.fieldName), {
              value: '',
              operator: 'contains'
          });
        }
      });
    },
    onPageChange(page) {
      this.paginationSettings.page = page;
      this.$emit("onPageChange", this.paginationSettings);
    },
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.data.forEach((res) => {
        res[0].checked = this.isSelectAll ? true : false;
      });
    },
    callDataChange() {
      this.$emit("onDataSelect", this.data);
    },
    showHeaderFilter(index) {
      this.isFilterMenuVisible[index] = true
    },
    hideHeaderFilter(index) {
      this.isFilterMenuVisible[index] = false
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      const filters = this.columnFilters;
      const finalFilters = {};
      for(const key in filters) {
        let stringKey = key;
        const object = filters[stringKey];
        if(object.value !== "") {
          let filterObject = this.convertFilter(object);
          this.$set(finalFilters, String(key), filterObject);
          this.hasColumnFilters = true
        }
      }
      this.$emit('onMultiFilter', finalFilters);
    },
    clearFilters() {
      this.initializeColumnFilters();
      this.$emit('onMultiFilter', {});
      this.hasColumnFilters = false;
    },
    convertFilter(object) {
      let filterObject = {}
      switch(object.operator) {
        case "contains":
          filterObject = { 
            $regex: object.value,
            $options: 'i'
          }
          break;
        case "notContains":
          filterObject = {
            $not: {
              $regex: object.value,
              $options: 'i'
            }
          }
          break;
        case "equal": 
          filterObject = {
            $eq: object.value
          }
          break;
        case "notEqual":
          filterObject = {
            $ne: object.value
          }
          break;
        case "startsWith":
          filterObject = {
            $regex: `^${object.value}`,
            $options: 'i'
          }
          break;
        case "endsWith":
          filterObject = {
            $regex: `${object.value}$`,
            $options: 'i'
          }
          break;
        case "numberEqual":
          filterObject = {
            $eq: parseFloat(object.value)
          }
          break;
        case "numberNotEqual":
          filterObject = {
            $ne: parseFloat(object.value)
          }
          break;
        case "lessThan":
          filterObject = {
            $lt: parseFloat(object.value)
          }
          break;
        case "lessThanEqual":
          filterObject = {
            $lte: parseFloat(object.value)
          }
          break;
        case "greaterThan":
          filterObject = {
            $gt: parseFloat(object.value)
          }
          break;
        case "greaterThanEqual":
          filterObject = {
            $gte: parseFloat(object.value)
          }
          break;
        case "dateEqual":
          filterObject = {
            $gte: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.000Z`,
            $lt: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T23:59:59.999Z`
          }
          break;
        case "dateNotEqual":
          filterObject = {
            $not: {
              $gte: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.000Z`,
              $lt: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T23:59:59.999Z`
            }
          }
          break;
        case "dateGreaterThan":
          filterObject = {
            $gt: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T23:59.59.999Z`
          }
          break;
        case "dateGreaterThanEqual":
          filterObject = {
            $gte: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.000Z`,
          }
          break;
        case "dateLessThan":
          filterObject = {
            $lt: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.000Z`,
          }
          break;
        case "dateLessThanEqual":
          filterObject = {
            $lte: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T23:59:59.999Z`
          }
          break;
        case "dateRange":
          filterObject = {
            $gte: `${moment(object.value, 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.000Z`,
            $lt: `${moment(object.value2, 'YYYY-MM-DD').format('YYYY-MM-DD')}T23:59:59.999Z`
          }
          break;
        case "boolEqual": 
          filterObject = {
            $eq: object.value == 'true' ? true : false
          }
          break;
      }
      return filterObject;
    },
    exportAsPDF() {
      
    }
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style lang="scss" scoped>
.toggle-checkbox:disabled {
  @apply bg-white;
}
.table-wrapper {
  @apply flex flex-col flex-1;
}
</style>