import { Vehicle, VehicleConfig } from "@/api";
import _ from 'lodash'

export default{
    // @Vehicle
    async createVehicle({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Vehicle.createVehicle(data)
            commit("setNewCreatedVehicle", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getVehicle({ state }, param){
        try{
            const response = await Vehicle.getVehicle(param);
            if(param.id){
                if(!response?.data?.data) return [];
                return response.data.data;
            }else{
                return { results : response?.data?.data, metadata:response.data.resultsMeta }
            }
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getVehicleByUID( { state }, param ){
        if(!param.businessUID) throw 'Invalid uid '+state.me;
        try{
            const response = await Vehicle.getVehicleByUID(param.businessUID, param.query);
            return { results : response.data.data, metadata: response.data?.resultsMeta }
        }catch(error){
            throw error.response.data
        }
    },
    
    async getVehicleByEntityId( { state, rootGetters }, param ){
        if(!param.entityId) throw 'Invalid entityId '+state.me;
        try{
            if (typeof param.query?.filter != 'object') param.query.filter = {}

            const proxySite = rootGetters[`account/proxySite`];
            if (!_.isEmpty(proxySite) && _.isObject(proxySite)) param.query.filter = { ...param.query.filter, siteId: proxySite._id };
            const response = await Vehicle.getVehicleByEntityId(param.entityId, param.query);
            return { results : response.data.data, metadata: response.data?.resultsMeta }
        }catch(error){
            throw error.response.data
        }
    },

    async getDuplicatedVehicleByEntityId( { state }, businessId ){
        if(!businessId) throw 'Invalid uid '+state.me;
        try{
            const response = await Vehicle.getDuplicatedVehicleByEntityId(businessId);
            return { results: response.data.data };
        }catch(error){
            throw error.response.data
        }
    },

    async getDefectedVehicleByEntityId( { state, rootGetters }, param ){
        if(!param.entityId) throw 'Invalid entityId '+state.me;
        try{
            if (typeof param.query?.filter != 'object') param.query.filter = {}

            const proxySite = rootGetters[`account/proxySite`];
            if (!_.isEmpty(proxySite) && _.isObject(proxySite)) param.query.filter = { ...param.query.filter, siteId: proxySite._id };

            const response = await Vehicle.getDefectedVehicleByEntityId(param.entityId, param.query);
            return { results : response.data.data, metadata: response.data?.resultsMeta }
        }catch(error){
            throw error.response.data
        }
    },

    async getVehicleByPartnerUID( { state }, param ){
        if(!param.businessUID) throw 'Invalid uid '+state.me;
        try{
            const response = await Vehicle.getVehicleByPartnerUID(param.businessUID, param.query);
            return { results : response.data.data, metadata: response.data?.resultsMeta }
        }catch(error){
            throw error.response.data
        }
    },
    async getVehicleByResellerUID( { state }, param ){
        if(!param.businessUID) throw 'Invalid uid '+state.me;
        try{
            const response = await Vehicle.getVehicleByResellerUID(param.businessUID, param.query);
            return { results : response.data.data, metadata: response.data?.resultsMeta }
        }catch(error){
            throw error.response.data
        }
    },
    async getCurrentVehicleCountForBusiness({ state }, payload) {
        if(!payload.businessId) throw "Invalid businessId value" + state.me;
        try{
            const response = await Vehicle.getCurrentVehicleCountForBusiness(payload);
            return response.data;
        } catch(error) {
            throw error.response.data;
        }
    },
    async updateVehicle( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Vehicle.patchVehicle(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteVehicle( { state }, id ){
        try{
            const response = await Vehicle.deleteVehicle(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async activateVehicle( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Vehicle.activate(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async deactivateVehicle( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Vehicle.deactivate(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async setAsDefectVehicle( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Vehicle.defect(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async setAsNonDefectVehicle( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Vehicle.setNonDefect(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async mergeVehicles( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Vehicle.mergeVehicles(data);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async moveVehicle( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Vehicle.moveVehicle(data);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateOdometer( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Vehicle.updateOdometer(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    // @VehicleClass
    async createVehicleClass({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createVehicleClass(data)
            commit("setNewCreatedVehicleClass", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getVehicleClass( { state }, id ){
        try{
            const response = await VehicleConfig.getVehicleClass(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getVehicleClassSearch( { state }, data ){
        try{
            const response = await VehicleConfig.getVehicleClassSearch(data);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateVehicleClass( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchVehicleClass(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteVehicleClass( { state }, id ){
        try{
            const response = await VehicleConfig.deleteVehicleClass(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @VehicleType
    async createVehicleType({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createVehicleType(data)
            commit("setNewCreatedVehicleType", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getVehicleType( { state }, id ){
        try{
            const response = await VehicleConfig.getVehicleType(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getVehicleTypeSearch( { state }, data ){
        try{
            const response = await VehicleConfig.getVehicleTypeSearch(data);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getActiveVehicleTypeSearch( { state }, data ){
        try{
            const response = await VehicleConfig.getActiveVehicleTypeSearch(data);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateVehicleType( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchVehicleType(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteVehicleType( { state }, id ){
        try{
            const response = await VehicleConfig.deleteVehicleType(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @RearCoupling
    async createRearCoupling({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createRearCoupling(data)
            commit("setNewCreatedRearCoupling", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getRearCoupling( { state }, id ){
        try{
            const response = await VehicleConfig.getRearCoupling(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getRearCouplingSearch( { state }, searchStr ){
        try{
            const response = await VehicleConfig.getRearCouplingSearch(searchStr);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getRearCouplingByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getRearCouplingByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getActiveRearCouplingByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getActiveRearCouplingByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateRearCoupling( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchRearCoupling(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteRearCoupling( { state }, id ){
        try{
            const response = await VehicleConfig.deleteRearCoupling(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @FrontCoupling
    async createFrontCoupling({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createFrontCoupling(data)
            commit("setNewCreatedFrontCoupling", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getFrontCoupling( { state }, id ){
        try{
            const response = await VehicleConfig.getFrontCoupling(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getFrontCouplingSearch( { state }, searchStr ){
        try{
            const response = await VehicleConfig.getFrontCouplingSearch(searchStr);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getFrontCouplingByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getFrontCouplingByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getActiveFrontCouplingByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getActiveFrontCouplingByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateFrontCoupling( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchFrontCoupling(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteFrontCoupling( { state }, id ){
        try{
            const response = await VehicleConfig.deleteFrontCoupling(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @CouplingCompatibility
    async createCouplingCompatibility({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createCouplingCompatibility(data)
            commit("setNewCreatedCouplingCompatibility", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getCouplingCompatibility( { state }, id ){
        try{
            const response = await VehicleConfig.getCouplingCompatibility(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getCouplingCompatibilitySearch( { state }, searchStr ){
        try{
            const response = await VehicleConfig.getCouplingCompatibilitySearch(searchStr);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getCouplingCompatibilityByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getCouplingCompatibilityByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateCouplingCompatibility( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchCouplingCompatibility(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteCouplingCompatibility( { state }, id ){
        try{
            const response = await VehicleConfig.deleteCouplingCompatibility(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @WiringPlugConnector
    async createWiringPlugConnector({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createWiringPlugConnector(data)
            commit("setNewCreatedWiringPlugConnector", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getWiringPlugConnector( { state }, id ){
        try{
            const response = await VehicleConfig.getWiringPlugConnector(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getWiringPlugConnectorSearch( { state }, searchStr ){
        try{
            const response = await VehicleConfig.getWiringPlugConnectorSearch(searchStr);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getWiringPlugConnectorByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getWiringPlugConnectorByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getActiveWiringPlugConnectorByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getActiveWiringPlugConnectorByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateWiringPlugConnector( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchWiringPlugConnector(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteWiringPlugConnector( { state }, id ){
        try{
            const response = await VehicleConfig.deleteWiringPlugConnector(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @WiringPlugVoltage
    async createWiringPlugVoltage({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createWiringPlugVoltage(data)
            commit("setNewCreatedWiringPlugVoltage", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getWiringPlugVoltage( { state }, id ){
        try{
            const response = await VehicleConfig.getWiringPlugVoltage(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getWiringPlugVoltageSearch( { state }, searchStr ){
        try{
            const response = await VehicleConfig.getWiringPlugVoltageSearch(searchStr);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getWiringPlugVoltageByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getWiringPlugVoltageByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getActiveWiringPlugVoltageByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getActiveWiringPlugVoltageByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateWiringPlugVoltage( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchWiringPlugVoltage(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteWiringPlugVoltage( { state }, id ){
        try{
            const response = await VehicleConfig.deleteWiringPlugVoltage(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @BrakeFoundation
    async createBrakeFoundation({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createBrakeFoundation(data)
            commit("setNewCreatedBrakeFoundation", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getBrakeFoundation( { state }, id ){
        try{
            const response = await VehicleConfig.getBrakeFoundation(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getBrakeFoundationSearch( { state }, searchStr ){
        try{
            const response = await VehicleConfig.getBrakeFoundationSearch(searchStr);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getBrakeFoundationByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getBrakeFoundationByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getActiveBrakeFoundationByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getActiveBrakeFoundationByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateBrakeFoundation( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchBrakeFoundation(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteBrakeFoundation( { state }, id ){
        try{
            const response = await VehicleConfig.deleteBrakeFoundation(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @BrakeType
    async createBrakeType({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createBrakeType(data)
            commit("setNewCreatedBrakeType", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getBrakeType( { state }, id ){
        try{
            const response = await VehicleConfig.getBrakeType(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getBrakeTypeSearch( { state }, searchStr ){
        try{
            const response = await VehicleConfig.getBrakeTypeSearch(searchStr);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getBrakeTypeByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getBrakeTypeByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getActiveBrakeTypeByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getActiveBrakeTypeByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateBrakeType( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchBrakeType(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteBrakeType( { state }, id ){
        try{
            const response = await VehicleConfig.deleteBrakeType(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @Suspension
    async createSuspension({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await VehicleConfig.createSuspension(data)
            commit("setNewCreatedBrakeType", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getSuspension( { state }, id ){
        try{
            const response = await VehicleConfig.getSuspension(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getSuspensionSearch( { state }, searchStr ){
        try{
            const response = await VehicleConfig.getSuspensionSearch(searchStr);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getSuspensionByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getSuspensionByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getActiveSuspensionByUID( { state }, uid ){
        try{
            const response = await VehicleConfig.getActiveSuspensionByUID(uid);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateSuspension( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await VehicleConfig.patchSuspension(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteSuspension( { state }, id ){
        try{
            const response = await VehicleConfig.deleteSuspension(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async updateVehicleTypeMassConfigs({ state }, payload) {
        try {
            const response = await VehicleConfig.updateVehicleTypeMassConfigs(payload);
            return response.data.data
        } catch(error) {
            console.log(state);
            throw error.response.data
        }
    },

    async updateVehicleMassConfig({state}, payload) {
        try {
            const id = payload.id;
            delete payload.id;
            const response = await Vehicle.updateVehicleMassConfig(id, payload);
            return response.data.data
        } catch(error) {
            console.log(state);
            throw error.response.data
        }
    },

    async mergeFleetVehicle({state}, payload) {
        try {
            await Vehicle.mergeFleetVehicle(payload);
        } catch (error) {
            console.log(state);
            throw error.response.data
        }
    },

    async fetchVehicleTypeImages({ state }, query) {
        try {
          const response = await VehicleConfig.fetchVehicleTypeImages(query);
          return response.data;
        } catch (error) {
          console.log(state);
          throw error.response.data;
        }
      },
    
      async uploadImageVehicleType({ state }, data) {
        if (!data || _.isEmpty(data)) throw "Invalid data object" + state.me;
        try {
          const response = await VehicleConfig.uploadImageVehicleType(data);
          return response.data.data;
        } catch (error) {
          throw error.response.data;
        }
      },

    async updateMultipleVehicleStatus( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Vehicle.updateMultipleStatus(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
}