<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit" class="relative">
      <div class="w-full" v-if="loading">
        <div
          class="absolute inset-0 z-10 flex flex-col items-center justify-center m-auto bg-gray-50"
        >
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300"
            >Initializing events...</span
          >
        </div>
      </div>
      <div class="mb-2">
        <div class="grid grid-cols-5 text-xs">
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Driver Name
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 border-2 border-t-0 border-gray-300"
            >
              {{ manualEventData.selectedDriver }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Fatigue Plan
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 uppercase border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ manualEventData.selectedPlan }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Vehicle
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ manualEventData.selectedVehicle || "&nbsp;" }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Event Date
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ manualEventData.selectedDate }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Time Zone
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ manualEventData.selectedTimezone }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex w-full h-10">
        <StyledButton
          @click.prevent="goToDate(true)"
          :disabled="disabledPrevBtn"
          :borderColor="theme.secondaryColor"
          :hoverBorderColor="theme.secondaryColor"
          class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
        >
          <font-awesome-icon icon="chevron-left" />
        </StyledButton>
        <div
          class="relative justify-center flex-1 text-sm leading-10 text-center text-gray-300 border-2 border-l-0 border-r-0 border-gray-300 manual-event-date"
          :class="[
            value.isActive ? 'active' : 'hidden',
            `manual-event-date-${value.dateOfSheet}`,
          ]"
          :style="{ color: theme.secondaryColor }"
          v-for="value in Object.values(eventDetails)"
          :key="value.dateOfSheet"
        >
          <span>{{ formatDate(value.dateOfSheet, "MMM DD, YYYY") }}</span>
        </div>
        <StyledButton
          @click.prevent="goToDate(false)"
          :disabled="disabledNextBtn"
          :borderColor="theme.secondaryColor"
          :hoverBorderColor="theme.secondaryColor"
          class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
        >
          <font-awesome-icon icon="chevron-right" />
        </StyledButton>
      </div>

      <div class="flex overflow-x-hidden">
        <div
          class="w-full manual-event-container"
          v-for="value in Object.values(eventDetails)"
          :class="[
            value.isActive ? 'active' : 'hidden',
            `manual-event-date-${value.dateOfSheet}`,
          ]"
          :key="value.dateOfSheet"
        >
          <div
            :id="`childLhv${value.dateOfSheet.replace(/-/g, '')}`"
            class="inline-block w-full manual-events"
          ></div>

          <Tabs class="mt-3 manual-event-tabs">
            <Tab title="Events">
              <div class="mt-3 overflow-x-auto border-2 border-gray-300">
                <table class="min-w-full">
                  <thead class="bg-gray-300">
                    <tr class="text-xs tracking-wider text-gray-700 uppercase">
                      <th scope="col" class="px-5 py-3 text-center">
                        Activity
                      </th>
                      <th scope="col" class="px-5 py-3 text-center">
                        Time of<br />Activity
                      </th>
                      <th scope="col" class="px-5 py-3 text-left">Location</th>
                      <th scope="col" class="px-5 py-3 text-center">
                        Odometer
                      </th>
                      <th scope="col" class="px-5 py-3 text-center">Vehicle</th>
                      <th
                        scope="col"
                        class="px-5 py-3 text-center"
                        style="min-width: 130px"
                      >
                        Work and<br />Rest Option
                      </th>
                      <th scope="col" class="px-5 py-3 text-left">Comments</th>
                      <th scope="col" class="px-5 py-3 text-center">Origin</th>
                      <th scope="col" class="px-5 py-3 text-left">
                        Entry Timestamp
                      </th>
                      <th scope="col" class="px-5 py-3 text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-show="value.events.length > 0"
                      v-for="(event, index) in value.events"
                      :key="index"
                      class="text-xs font-medium text-gray-500 bg-white border-b"
                    >
                      <td
                        class="px-5 py-3 text-center capitalize text-black-primary"
                      >
                        {{ event.type }}
                      </td>
                      <td class="px-5 py-3 text-center">
                        {{ event.timeOfActivity }}
                      </td>
                      <td class="px-5 py-3">
                        {{ event.locationAddress }}
                      </td>
                      <td class="px-5 py-3 text-center">
                        {{ event.odometer }}
                      </td>
                      <td class="px-5 py-3 text-center">
                        {{ event.vehicle }}
                      </td>
                      <td class="px-5 py-3 text-center uppercase">
                        {{ event.fatiguePlan }}
                      </td>
                      <td class="px-5 py-3">
                        {{ event.comment }}
                      </td>
                      <td class="px-5 py-3 text-center capitalize">
                        {{ event.origin }}
                      </td>
                      <td class="px-5 py-3">
                        {{ event.originalStartTime }}
                      </td>
                      <td class="px-5 py-3 uppercase">
                        {{ event.status }}
                      </td>
                    </tr>
                    <tr v-show="value.events.length == 0">
                      <td colspan="10" class="px-5 py-3 text-sm text-center">
                        No events found.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab title="Breaches" :badge="breaches.length">
              <div class="mt-2 overflow-x-auto border-2 border-gray-300">
                <table class="min-w-full">
                  <thead class="bg-gray-300">
                    <tr class="text-xs tracking-wider text-gray-700 uppercase">
                      <th scope="col" class="px-5 py-3 text-left">
                        Counting Point
                      </th>
                      <th scope="col" class="px-5 py-3 text-left">
                        Breach Time
                      </th>
                      <th scope="col" class="px-5 py-3 text-left">Option</th>
                      <th scope="col" class="px-5 py-3 text-left">Rule</th>
                      <th scope="col" class="px-5 py-3 text-left">Rule Type</th>
                      <th scope="col" class="px-5 py-3 text-left">Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-show="breaches.length > 0"
                      v-for="(breach, index) in breaches"
                      :key="index"
                      class="text-xs font-medium text-gray-500 bg-white border-b"
                    >
                      <td
                        class="px-5 py-3 text-left capitalize text-black-primary"
                      >
                        {{ formatTime(breach.startPoint, breach.timezone) }}
                      </td>
                      <td
                        class="px-5 py-3 text-left capitalize text-black-primary"
                      >
                        {{ formatTime(breach.time, breach.timezone) }}
                      </td>
                      <td class="px-5 py-3">
                        {{ breach.option }}
                      </td>
                      <td class="px-5 py-3">
                        {{ breach.period }}
                      </td>
                      <td class="px-5 py-3">
                        {{ breach.type }}
                      </td>
                      <td class="px-5 py-3">
                        {{ cleanLevel(breach.level) }}
                      </td>
                    </tr>
                    <tr v-show="breaches.length == 0">
                      <td colspan="10" class="px-5 py-3 text-sm text-center">
                        No breaches found.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 my-5">
        <button
          class="w-full bg-gray-300 btn hover:bg-gray-400"
          @click.prevent="onPrevious()"
        >
          Back
        </button>
        <button
          type="submit"
          class="w-full btn btn-primary"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="submitting"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!submitting">Submit</span>
        </button>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import { StyledButton } from "@/styled-components";
import { getStateSubInAddress } from "@/_helper";
import Tab from "@/components/commons/tab/Tab";
import Tabs from "@/components/commons/tab/Tabs";
import Spinner from "@/components/commons/ui/Spinner";
import LocationData from "@/assets/location/location.json";
import LogmasterManualEvents from "@logmaster/logmaster-manual-events";
import LogmasterWahvaManualEvents from "@logmaster/logmaster-wahva-manual-events";
import { mapGetters } from "vuex";

export default {
  name: "Manual-Event-Confirmation",
  props: {
    msg: String,
    preData: null,
  },
  components: { Spinner, Tabs, Tab, StyledButton },
  data() {
    return {
      submitting: false,
      manualEvent: null,
      loading: false,
      values: {},
      errorMessage: "",
      schema: [],
      file: null,
      breaches: [],
      eventDetails: [],
      dateFormat: "YYYY-MM-DD",
      disabledPrevBtn: false,
      disabledNextBtn: false,
      dateRange: [],
      stepsData: [],
      engineRegion: null,
    };
  },
  computed: {
    manualEventData: {
      get() {
        const vehiclePlate =
          typeof this.values.selectedVehicle == "string"
            ? this.values.selectedVehicle
            : this.values.selectedVehicle?.vehiclePlate;
        return {
          selectedDriver: this.values.selectedDriver?.driver?.driverName,
          selectedPlan: this.values.selectedPlan,
          selectedVehicle: vehiclePlate,
          selectedDate:
            this.dateRange[0] == this.dateRange[1]
              ? this.dateRange[0]
              : `${this.dateRange[0]} to ${this.dateRange[1]}`,
          selectedTimezone: this.values.selectedTimezone,
        };
      },
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  async mounted() {
    let preData = this.preData;
    if (!_.isEmpty(preData)) {
      if (preData?.stepsData) {
        this.stepsData = preData.stepsData;
      }

      if (preData?.engineRegion) {
        this.engineRegion = preData.engineRegion;
      }

      if (preData?.file) {
        this.file = preData?.file;
      }

      if (preData?.breaches) {
        this.breaches = preData?.breaches;
      }

      if (preData?.eventDetails) {
        this.loading = true;

        // Setup manual event styles
        let logmasterManualEvents = null;
        if (this.engineRegion == "WAHVA") {
          logmasterManualEvents = new LogmasterWahvaManualEvents();
        } else {
          logmasterManualEvents = new LogmasterManualEvents();
        }
        $("head").append(
          `<style type="text/css">${logmasterManualEvents.getStyles()}</style>`
        );

        for (const [index, details] of preData.eventDetails.entries()) {
          if (index == 0) this.values = details;

          details.isActive = false;
          details.manualEvent = null;
          this.eventDetails[details.dateOfSheet] = details;
        }
        Object.values(this.eventDetails)[0].isActive = true;
        this.disabledPrevBtn = true;
        this.disabledNextBtn =
          Object.values(this.eventDetails).length > 1 ? false : true;
        this.dateRange = this.values.selectedDateRange;

        // Add delay for the dom elements to get created
        setTimeout(() => {
          const timeStamp = moment().toISOString();
          for (let value of Object.values(this.eventDetails)) {
            value.breaches = this.breaches;
            value.events.forEach((event) => {
              const location = !_.isEmpty(event["location"]["address"])
                ? event["location"]["address"]
                : event["location"]["formatted_address"];
              const rawLocation = getStateSubInAddress(location);
              event["suburb"] = !_.isEmpty(rawLocation)
                ? rawLocation["suburb"]
                : null;
              event["locationAddress"] = !_.isEmpty(event["suburb"])
                ? event["suburb"]
                : location;

              if (_.isEmpty(event["timezone"]))
                event["timezone"] = value.selectedTimezone;
              const timezone =
                !_.isEmpty(event["timezone"]) && event["timezone"].length > 1
                  ? event["timezone"].split(" ")[1]
                  : event["timezone"];
              event["offset"] = timezone ? timezone : "+10:00";
              event["timeOfActivity"] = moment(event.startTimeWithTimezone)
                .utcOffset(event["offset"])
                .format("HH:mm");

              if (
                event.origin.toLowerCase() == "historic" &&
                _.isEmpty(event.eventId)
              ) {
                const timezone = event.timezone.split(' ')[1];
                event["originalStartTime"] = moment(timeStamp).utcOffset(timezone).format('YYYY-MM-DDTHH:mm:ssZ')
              }
            });

            value.manualEvent = $(
              `#childLhv${value.dateOfSheet.replace(/-/g, "")}`
            );
            this.manualEventInstance(value.manualEvent, {
              isViewMode: true,
              isDateVisible: true,
              driverId: value.selectedDriver?.driverId,
              vehicleId: value.selectedVehicle,
              timezone: value.selectedTimezone,
              fatiguePlan: value.selectedPlan,
              locationData: LocationData,
              currentDate: value.dateOfSheet,
              currentEvents: value.events,
              previousEventType: value.previousEventType,
              previousEvent: value.previousEvent,
            });

            value.breaches = value.breaches.filter((breach) => {
              const breachTime = moment(breach.time)
                .utcOffset(breach.timezone)
                .format("YYYY-MM-DD");
              return value.dateOfSheet == breachTime;
            });
            this.setBreaches(value.manualEvent, value.breaches);
          }
          this.loading = false;
        }, 1000);
      }
    }
  },
  methods: {
    manualEventInstance(elObject, method) {
      return this.engineRegion == "WAHVA"
        ? elObject.WahvaManualEvent(method)
        : elObject.ManualEvent(method);
    },
    setBreaches(elObject, breaches) {
      return this.engineRegion == "WAHVA"
        ? elObject.WahvaManualEvent("setBreaches", breaches)
        : elObject.ManualEvent("setBreaches", breaches);
    },
    onPrevious() {
      this.$emit("backStep", this.stepsData);
    },
    async onSubmit() {
      const me = this.$store.getters[`account/me`];
      let entityRole = !_.isEmpty(me['driver']) ? 'driver' : 'business';
      let entityId = me[entityRole]._id;

      if (entityRole == "driver") {
        entityId = me[entityRole].entityId;
        entityRole = me[entityRole].parentRole;
      }

      const metadata = {
        pending: false,
        eventId: null,
        metadata: {
          "two-up-driver-information": {
            udi: this.values.twoUpUDI,
            driverName: this.values.twoUpName,
            "2up_fatigue_plan": this.values.twoUpFatiguePlan,
            driversLicenseNumber: this.values.twoUpLicenseNumber,
            driversLicenseState: this.values.twoUpLicenseState,
          },
        },
      };

      let historicEvents = [];
      for (let value of Object.values(this.eventDetails)) {
        const events = this.manualEventInstance(
          value.manualEvent,
          "getEvents"
        ).filter((event) => {
          if (
            event.origin.toLowerCase() == "historic" &&
            _.isEmpty(event.eventId)
          ) {
            event = this.formatEvent(event, metadata, true);
            return event;
          }
        });

        let updatedEvents = _.cloneDeep(value?.updatedEvents) || [];
        const allUpdatedEvents = updatedEvents.filter((event) => {
          if (!_.isEmpty(event.eventId)) {
            event = this.formatEvent(event, metadata, false);
            return event;
          }
        });

        historicEvents = [...historicEvents, ...events, ...allUpdatedEvents];
      }

      const allBreaches = this.breaches.map((breach) => {
        breach.entityId = entityId;
        breach.level = this.cleanLevel(breach.level);
        if (typeof breach.time == "object") breach.time = breach.time[0];
        return _.omit(breach, "isChecked");
      });
      const data = {
        events: historicEvents,
        breaches: allBreaches,
        file: this.file,
        entityId: entityId,
        entityRole: entityRole,
        dateRange: this.dateRange.join(" to "),
      };

      this.submitting = true;
      await this.$store
        .dispatch(`business/createManualEvents`, data)
        .then(() => {
          this.toast("success", "Manual events saved.");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.toast("error", err.message);
        });
      this.submitting = false;
    },
    formatEvent(event, metadata, removeEventId = true) {
      if (removeEventId) delete event.eventId;
      delete event.date;
      delete event.stationary;
      delete event.startTime;
      delete event.endIndex;
      delete event.endTime;
      delete event.locationAddress;
      delete event.offset;
      delete event.startIndex;
      delete event.suburb;
      delete event.timeOfActivity;
      if (_.isEmpty(event.comment)) {
        event.comment = null;
      }
      event.origin = event.origin.toLowerCase();
      event.metadata = [];
      if (!_.isEmpty(this.values.twoUpUDI)) {
        event["metadata"].push(metadata);
      }
      if (!_.isEmpty(this.values.selectedFatigueTable)) {
        event["fatigueTable"] = this.values.selectedFatigueTable.toLowerCase();
      }

      return event;
    },
    async toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    formatTime(time, timezone) {
      return moment(time).utcOffset(timezone).format("YYYY-MM-DD HH:mm:ssZ");
    },
    cleanLevel(level) {
      if (typeof level == "object") level = level[0];
      return _.startCase(
        level == "no-breach" ? level.replace("-", " ") : level.split("-")[0]
      );
    },
    formatDate(date) {
      return moment(date, this.dateFormat).format("MMMM DD, YYYY");
    },
    isDateNotBefore(date) {
      return moment(date, this.dateFormat).isBefore(this.dateRange[0]);
    },
    isDateNotAfter(date) {
      return moment(date, this.dateFormat).isAfter(this.dateRange[1]);
    },
    isPrevDisable(dateValue) {
      return this.isDateNotBefore(
        moment(dateValue, this.dateFormat)
          .subtract(1, "days")
          .format(this.dateFormat)
      );
    },
    isNextDisable(dateValue) {
      return (
        this.isDateNotAfter(
          moment(dateValue, this.dateFormat)
            .add(1, "days")
            .format(this.dateFormat)
        ) ||
        moment().format(this.dateFormat) ==
          moment(dateValue, this.dateFormat).format(this.dateFormat)
      );
    },
    goToDate(isPrevDate = false) {
      let currentActive = Object.values(this.eventDetails).find(
        (x) => x.isActive
      );
      const newDate = isPrevDate
        ? moment(currentActive.dateOfSheet, this.dateFormat).subtract(1, "days")
        : moment(currentActive.dateOfSheet, this.dateFormat).add(1, "days");
      const activeDate = newDate.format(this.dateFormat);

      // Set current active to false, send new value as active
      currentActive.isActive = false;
      this.eventDetails[activeDate].isActive = true;
      $(`.manual-event-date-${currentActive.dateOfSheet}`).hide();
      $(
        `.manual-event-date-${this.eventDetails[activeDate].dateOfSheet}`
      ).show();
      this.setNavigationButton(this.eventDetails[activeDate].dateOfSheet);
    },
    setNavigationButton(date) {
      this.disabledPrevBtn = this.isPrevDisable(date) ? true : false;
      this.disabledNextBtn = this.isNextDisable(date) ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.manual-events::v-deep {
  .timetable-container {
    @apply border-2 border-gray-300 rounded-b-none rounded-r-none;

    .manual-event-date {
      @apply border-r-0 rounded-none;
    }
    .timetable-header_work,
    .timetable-header_rest {
      @apply border-r-0;
    }

    #events-table .g-bg-blue-200 {
      @apply border-gray-300;
    }
    .ruler-text .time-block:last-child,
    .ruler-text .time-block:first-child span {
      top: 2px !important;
    }
  }
}
.manual-event-tabs::v-deep ul li button {
  @apply text-sm font-bold;
}
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
