<template>
  <div>
    <Stepper
        :stepperSteps="stepperSteps"
        @completed-step="completeStep"
        @active-step="isStepActive"
        :showNavigation="false"
        @closeRefresh="closeRefresh"
    />
  </div>
</template>

<script>
import { Stepper } from '@/components/commons'
import { ContractorDetailsForm, SelectDriver, InviteDriver }  from './stepper-component'
export default {
  name: 'New-Contractor-Import-Form',
  props: {
    isNonEwdImport: {
      type: Boolean,
      required: false,
      default: false
    },
    availableSeats:{
      type: [String, Number],
      default: 0,
    },
  },
  components: {Stepper},
  mounted () {
    console.log("availableSeats",this.availableSeats);
  },
  data(){
    return{
      stepperSteps: [
        {
          stepIcon: 'download',
          stepTitle: 'Contractor Details',
          stepSequence: 'first',
          stepComponent: ContractorDetailsForm,
          isCompleted: true
        },
        {
          stepIcon: 'users',
          stepTitle: 'Select Drivers',
          stepSequence: 'second',
          stepComponent: SelectDriver,
          availableSeats: typeof this.availableSeats == 'string' ? -1 : this.availableSeats,
          isCompleted: false
        },
        {
          stepIcon: 'download',
          stepTitle: 'Send Invites',
          stepSequence: 'third',
          stepComponent: InviteDriver,
          isCompleted: false
        },
      ],
      activeStep: 0,
    }
  },
  methods: {
    completeStep(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          step.isCompleted = true;
        }
      })
    },
    isStepActive(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          if(step.isCompleted === true) {
            step.isCompleted = false;
          }
        }
      })
    },
    closeRefresh(){
      this.$emit('closeRefresh')
    }
  },

}
</script>