<template>
  <div>
    <Table
      :isLoading="isLoading"
      :hasActions="true"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="driversData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            v-on:keyup.enter="filterChange"
          />
        </div>
        <div class="block relative">
          <p class="text-sm text-red-500 whitespace-no-wrap p-2 ml-5 font-bold">These businesses are created by you in the mobile application, if you wish to delink one here, click the actions and select delink.</p>
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewDriver(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="eye" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer whitespace-nowrap"
                    @click.prevent="manageVehicles(data.businessId)"
                    :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="car" class="my-auto mr-2" />
                  Manage Vehicles
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>
    <Modal
      ref="modal"
      :title="modal.modalTitle"
      size="3xl"
      :height="modal.modalHeight"
    >
      <div v-if="modal.modalContent === 'view'">
        <BusinessDetailsView :detailsId="modal.modalId" />
      </div>
      <div v-if="modal.modalContent === 'edit'">
        <EditUserCreateBusinessProfile
          :detailsId="modal.modalId"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modal.modalContent === 'manage-vehicles'">
        <ManageVehicles
          :detailsId="modal.modalId"
          @closeModal="closeModal"
        />
      </div>
    </Modal>
  </div>
</template>

<script>

import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import { Dropdown, Modal, Table } from "@/components/commons";
import { formatDate, formatPaginationSettings } from "@/_helper";
import { ManageVehicles } from "@/components/forms/driver/vehicles/";
import { EditUserCreateBusinessProfile } from "@/components/forms/business/driver/";
import BusinessDetailsView from "@/components/view/business/view-user-create-business-driver-link";

export default {
  name: "Linked-Businesses-Driver",
  components: {
    Table,
    Modal,
    Dropdown,
    StyledIconedLink,
    ManageVehicles,
    BusinessDetailsView,
    EditUserCreateBusinessProfile,
  },
  data() {
    return {
      isLoading: false,
      driverId: "",
      businesses: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      tableHeader: ["Business Name", "Date Created"],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      filterStatuses: {
        all: "All",
        linked: "Linked",
        pending: "Pending",
      },
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: "80vh",
      },
      activeBusiness: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.init();
  },
  methods: {
    async init(paged = 1) {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };
      if (this.filter.status !== "all")
        query.filter.status = { $eq: this.filter.status };
      this.driverId = me?.driver?._id;
      let driverContract = await this.$store.dispatch(
        `driver/getExternalContractByDriver`,
        { uid: this.driverId, query }
      );
      if (driverContract?.metadata) {
        this.paginationSettings = formatPaginationSettings(
          driverContract?.metadata
        );
        driverContract = driverContract.results;
      }
      if (driverContract) {
        this.businesses = this.formatItem(
            _.orderBy(driverContract, ["createdAt"], ["desc"])
        );
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item?._id,
            businessId: item?.businessId ? item.businessId : "",
            name: item?.business?.persona
              ? item.business.persona?.businessName
              : "",
            created: item?.createdAt ? formatDate(item?.createdAt) : "",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    parseDrivers(data) {
      return data.map((driverData) => [
        {
          businessId: driverData.businessId,
          id: driverData.id,
          name: driverData.name,
          itemType: "name",
          hasImage: true,
          status: driverData.status,
        },
        {
          businessId: driverData.businessId,
          id: driverData.id,
          name: driverData.created,
          itemType: "string",
        },
      ]);
    },
    viewDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "view";
      this.modal.modalTitle = "Driver Info";
      this.modal.modalId = id;
    },
    editDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "edit";
      this.modal.modalTitle = "User Created Business - Profile Edit";
      this.modal.modalId = id;
    },
    manageVehicles(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "manage-vehicles";
      this.modal.modalTitle = "User Created Business - Manage Vehicles";
      this.modal.modalId = id;
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
  },
  computed: {
    driversData() {
      if (this.businesses) {
        return this.parseDrivers(this.businesses);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
