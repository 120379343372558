<template>
  <div>
    <NormalSectionView
      v-if="section.type === 'normal'"
      :section="buildSection"
      :uniqueId="uniqueId"
      v-on="$listeners"
    />
    <ToggleableSectionView
      v-else-if="section.type === 'toggle'"
      :section="buildSection"
      :controls="controls"
      :uniqueId="uniqueId"
    />
  </div>
</template>
<script>
import { HELPER } from '@/api/helper';
import NormalSectionView from '@/views/builder/section-views/NormalSectionView.vue'
import ToggleableSectionView from '@/views/builder/section-views/ToggleableSectionView.vue'

export default {
  components: {
    NormalSectionView,
    ToggleableSectionView
  },

  props: {
    section: Object,
    controls: Array,
    uniqueId: String
  },

  data: () => ({
    show: false,
    buildSection: {}
  }),

  watch: {
    section: {
      deep: true,
      handler() {
        this.setSectionData()
      }
    }
  },

  created() {
    this.setSectionData()
  },

  methods: {
    setSectionData() {
      this.buildSection = HELPER.cloneDeep(this.section)
    }
  }
}
</script>
