<template>
  <div class="h-full border-r">
    <aside class="w-96">
      <div class="overflow-y-auto pt-4 pb-1 px-3 rounded-md flex flex-col">
        <p class="text-sm">Document Variables</p>
      </div>

      <div class="p-2">
        <a
          class="bg-slate-100 rounded-lg flex items-center p-2 text-sm border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
          @click.prevent="selectedDocPart('Headers')"
        >
          <span>Header</span>
        </a>
      </div>

      <div class="p-2">
        <a
          class="bg-slate-100 rounded-lg flex items-center p-2 text-sm border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
          @click.prevent="selectedDocPart('Paragraph')"
        >
          <span>Paragraph</span>
        </a>
      </div>

      <div class="p-2">
        <a
          class="bg-slate-100 rounded-lg flex items-center p-2 text-sm border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
          @click.prevent="selectedDocPart('Addresses')"
        >
          <span>Addresses</span>
        </a>
      </div>

      <div class="p-2">
        <a
          class="bg-slate-100 rounded-lg flex items-center p-2 text-sm border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
          @click.prevent="selectedDocPart('Title')"
        >
          <span>Title</span>
        </a>
      </div>

      <div class="p-2">
        <a
          class="bg-slate-100 rounded-lg flex items-center p-2 text-sm border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
          @click.prevent="selectedDocPart('bulletPoints')"
        >
          <span>Bullet Points</span>
        </a>
      </div>

      <div class="p-2">
        <a
          class="bg-slate-100 rounded-lg flex items-center p-2 text-sm border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
          @click.prevent="selectedDocPart('schemeType')"
        >
          <span>Scheme Type</span>
        </a>
      </div>

      <div class="p-2">
        <a
          class="bg-slate-100 rounded-lg flex items-center p-2 text-sm border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
          @click.prevent="selectedDocPart('Closing')"
        >
          <span>Closing</span>
        </a>
      </div>

      <div class="overflow-y-auto pt-4 pb-1 px-3 rounded-md flex flex-col">
        <p class="text-sm">Draggable Closing Elements</p>
        <p class="text-xs text-red-500">You can only drag and drop it inside "Closing" element</p>
      </div>

      <draggable
        tag="ul"
        :list="closingElements"
        :group="{ name: 'footer', pull: 'clone', put: false }"
        :sort="false"
        class="space-y-2 px-2"
        :clone="onCloneFooter"
      >
        <li v-for="item in closingElements" :key="item.id">
          <a
            class="pb-1 bg-slate-100 rounded-lg flex items-center p-2 text-sm cursor-move border-2 border-solid hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
          >
            <span>{{ item.name }}</span>
          </a>
        </li>
      </draggable>
    </aside>
  </div>
</template>

<script>
import { HELPER } from "@/api/helper";
import draggable from "vuedraggable";
export default {
  name: "document-builder",

  components: {
    draggable,
  },

  data() {
    return {
      headerElements: [
        {
          id: "left-logo",
          name: "Left Logo",
          type: "Header",
          part: "left",
        },
        {
          id: "right-logo",
          name: "Right Logo",
          type: "Header",
          part: "right",
        },
      ],

      closingElements: [
        {
          id: "admin-signature",
          name: "Admin Signature",
          type: "Admin Signature",
        },
        {
          id: "signature-line",
          name: "Signature Line",
          type: "Signature Line",
        },
      ],
    };
  },

  methods: {
    selectedDocPart(type) {
      this.$emit("selectedDocPart", type);
    },

    onCloneHeader(e) {
      const generatedId = `${e.type}` + HELPER.getUUIDv4();

      const { type, part } = e;
      let formData = {};

      formData.id = generatedId;
      formData.uuid = generatedId;
      formData.type = type;
      formData.part = part;
      formData.value;
      formData.isSection = false;

      return formData;
    },

    onCloneFooter(e) {
      const generatedId = `${e.type}` + HELPER.getUUIDv4();

      const { type } = e;
      let formData = {};

      formData.id = generatedId;
      formData.uuid = generatedId;
      formData.type = type;

      formData.value;
      formData.isSection = false;

      return formData;
    },
  },
};
</script>

<style></style>
