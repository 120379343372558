<template>
  <div class="w-full">
    <div v-if="!isSuccess" class="h-full w-full">
      <div v-if="!isLoading" class="flex items-center p-4 bg-gray-100 rounded">
        <font-awesome-icon
          icon="info-circle"
          size="2x"
          :style="{ color: theme.primaryColor }"
        />
        <strong class="ml-1 uppercase font-semibold text-sm"
          >Search a vehicle mass report.</strong
        >
      </div>
      <div
        v-if="isLoading"
        class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center"
      >
        <font-awesome-icon icon="cog" spin size="4x" class="text-gray-400" />
        <h6 class="mt-4">Loading...</h6>
      </div>
    </div>

    <div v-if="isSuccess" class="w-full mt-5 compliance-tabs-wrapper">
      <div class="w-full border border-gray-200 rounded p-5">
        <h4 class="text-orange-primary mb-1 mt-5 font-bold">Overview</h4>
        <table class="w-full mb-5">
          <thead>
            <tr class="bg-blue text-xs text-left text-white border">
              <th scope="col" class="px-3 py-1">Total Trips</th>
              <th scope="col" class="px-3 py-1">Finalised Trips</th>
              <th scope="col" class="px-3 py-1">Active Trips</th>
              <th scope="col" class="px-3 py-1">Pending Trips</th>
              <th scope="col" class="px-3 py-1">Cancelled Trips</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border">
              <td class="py-2 px-3 text-sm border-r border-b bg-gray-300">
                {{ reportData.breakdown.total }}
              </td>
              <td class="py-2 px-3 text-sm border-r border-b bg-gray-300">
                {{ reportData.breakdown.finalised }}
              </td>
              <td class="py-2 px-3 text-sm border-r border-b bg-gray-300">
                {{ reportData.breakdown.active }}
              </td>
              <td class="py-2 px-3 text-sm border-r border-b bg-gray-300">
                {{ reportData.breakdown.pending }}
              </td>
              <td class="py-2 px-3 text-sm border-r border-b bg-gray-300">
                {{ reportData.breakdown.cancelled }}
              </td>
            </tr>
          </tbody>
        </table>
        <h4 class="text-orange-primary mb-1 mt-5 font-bold">
          Vehicle Information
        </h4>
        <table class="w-full mb-5">
          <thead>
            <tr class="bg-blue text-xs text-left text-white border">
              <th scope="col" class="px-3 py-1">Vehicle Plate</th>
              <th scope="col" class="px-3 py-1">Type</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border">
              <td class="py-2 px-3 text-sm border-r border-b bg-gray-300">
                {{ selectedVehicle?.vehiclePlate }}
              </td>
              <td class="py-2 px-3 text-sm border-r border-b bg-gray-300">
                {{ selectedVehicle?.vehicleType?.name }}
              </td>
            </tr>
          </tbody>
        </table>
        <h4 class="text-orange-primary mb-1 font-bold">Driver Trips</h4>
        <span class="text-xs"><i> Previewing only 10 records. </i></span>
        <table class="w-full">
          <thead>
            <tr class="bg-blue text-xs text-left text-white border">
              <th scope="col" class="px-3 py-1">Loaded From</th>
              <th scope="col" class="px-3 py-1">Delivered To</th>
              <th scope="col" class="px-3 py-1">Date</th>
              <th scope="col" class="px-3 py-1">Material</th>
              <th scope="col" class="px-3 py-1">Docket Number</th>
              <th scope="col" class="px-3 py-1">Mass Limit</th>
              <th scope="col" class="px-3 py-1">Gross</th>
              <th scope="col" class="px-3 py-1">Net</th>
              <th scope="col" class="px-3 py-1">Weights</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border"
              v-for="(data, index) in reportData.trips"
              :key="index"
            >
              <td
                class="py-2 px-3 text-sm text-center border-l border-b bg-gray-300"
              >
                {{ data.location }}
              </td>
              <td
                class="py-2 px-3 text-sm text-center border-l border-r border-b bg-gray-300"
              >
                {{ data.destination }}
              </td>
              <td
                class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300"
              >
                {{ formatDate(data.loadTime) }}
              </td>
              <td
                class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300"
              >
                {{ data.loadDescription || "" }}
              </td>
              <td
                class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300"
              >
                {{ data.docketNumber || "" }}
              </td>
              <td
                class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300"
              >
                {{ data.massLimit || "" }}
              </td>
              <td
                class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300"
              >
                {{ data.grossWeight || "" }}
              </td>
              <td
                class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300"
              >
                {{ data.netWeight || "" }}
              </td>
              <td
                class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300 hover:text-orange cursor-pointer"
                @click="viewTrailerPlates(index)"
              >
                View
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Modal
      ref="modal"
      :title="modal.title"
      size="lg"
      :height="modal.height"
      @closeModal="closeModal"
    >
      <div v-if="modal.content === 'view-trailers'">
        <section class="mt-5 border rounded-lg group bg-gray-100">
          <header class="flex">
            <div class="pb-2 font-bold pt-3 px-3">Totals</div>
          </header>
          <hr />
          <div class="px-5 py-5 bg-white">
            <table>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Gross:</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{ selectedTrip.grossWeight }}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Net:</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{ selectedTrip.netWeight }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </section>
        <section class="mt-5 border rounded-lg group bg-gray-100">
          <header class="flex">
            <div class="pb-2 font-bold pt-3 px-3">Drive Vehicle</div>
          </header>
          <hr />
          <div class="px-5 py-5 bg-white">
            <table>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Plate Number:</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{ selectedTrip.vehiclePlate }}</div>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Steer:</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{ selectedTrip.steer }}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Drive:</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{ selectedTrip.drive }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </section>
        <div
          class="mb-2"
          v-for="(trailer, index) in selectedTrip.trailers"
          :key="index"
        >
          <section class="mt-5 border rounded-lg group bg-gray-100">
            <header class="flex">
              <div class="pb-2 font-bold pt-3 px-3">
                Trailer #{{ index + 1 }}
              </div>
            </header>
            <hr />
            <div class="px-5 py-5 bg-white">
              <table>
                <tr>
                  <td class="pb-1">
                    <div class="font-semibold">Plate Number:</div>
                  </td>
                  <td class="pb-1">
                    <div class="ml-3">{{ trailer.trailerPlate }}</div>
                  </td>
                </tr>
                <tr>
                  <td class="pb-1">
                    <div class="font-semibold">Axles:</div>
                  </td>
                  <td class="pb-1">
                    <div class="ml-3">
                      {{
                        trailer.axles && trailer.axles.length
                          ? trailer.axles.toString()
                          : "N/A"
                      }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from "lodash";
import { Modal } from "@/components/commons";
import moment from "moment";
import { mapGetters } from "vuex";
import { formatDate } from "@/_helper";

export default {
  name: "VehicleReport",
  components: {
    Modal,
  },
  props: {},
  data() {
    return {
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      isLoading: false,
      isSuccess: false,
      selectedDateRange: [],
      selectedVehicle: null,
      reportData: {
        trips: [],
        breakdown: {
          total: 0,
          active: 0,
          pending: 0,
          finalised: 0,
          cancelled: 0,
        },
      },
      selectedTrip: {},
    };
  },
  methods: {
    canSearch() {
      return (
        !_.isEmpty(this.selectedVehicle) &&
        this.selectedDateRange.length &&
        this.selectedDateRange[0]
      );
    },

    async onSearch(selectedVehicle, selectedDateRange) {
      this.selectedVehicle = selectedVehicle;
      this.selectedDateRange = selectedDateRange;

      this.isLoading = true;
      this.isSuccess = false;

      const payload = {
        vehiclePlate: selectedVehicle.vehiclePlate,
        startDate: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        endDate: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
      };

      const response = await this.$store.dispatch(
        `report/getVehicleMassReport`,
        payload
      );

      this.reportData =
        response && response.trips && response.trips.length
          ? response
          : {
              trips: [],
              breakdown: {
                total: 0,
                active: 0,
                pending: 0,
                finalised: 0,
                cancelled: 0,
              },
            };

      // Take only first 10 for preview
      this.reportData.trips = this.reportData.trips.slice(0, 10);
      this.isLoading = false;
      this.isSuccess = true;
    },

    async onSubmitSendReport(recipientEmail) {
      if (this.canSearch() && recipientEmail) {
        this.$emit("sendReportloading", true);

        const data = {
          recipientEmail: recipientEmail,
          vehicleId: this.selectedVehicle._id,
          startDate: moment(this.selectedDateRange[0]).format("YYYY-MM-DD"),
          endDate: moment(this.selectedDateRange[1]).format("YYYY-MM-DD"),
        };

        try {
          await this.$store.dispatch(`business/sendVehicleMassReport`, data);
          this.toast("success", "Report sent successfully");
        } catch (error) {
          this.toast("error", error.message);
        }

        this.$emit("sendReportloading", false);
      }
    },

    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },

    formatDate(date) {
      return formatDate(date, undefined, true);
    },
    viewTrailerPlates(index) {
      this.$refs.modal.openModal();
      this.modal.content = "view-trailers";
      this.modal.title = "Weights";
      this.selectedTrip = this.reportData.trips[index];
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
