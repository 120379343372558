<template>
  <div
    :data-type="context.type"
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
  >
    <multiselect
      ref="refMultiselect"
      v-model="value"
      :options="drivers"
      :multiple="true"
      :loading="isLoading"
      :close-on-select="false"
      :clear-on-select="false"
      :showNoResults="true"
      :showNoOptions="true"
      :object="true"
      selectLabel="Click to select"
      deselectLabel="Click to deselect"
      placeholder="Type to search"
      track-by="driverId"
      label="driver.driverName"
      :custom-label="({ driver }) => `${driver?.driverName}`"
      @select="onSelect"
      @remove="onRemove"
      @search-change="onSearch"
    >
      <template slot="option" slot-scope="{ option }">
        <h3 class="m-0 font-semibold truncate">
          {{ option.driver.driverName }}
        </h3>
        <div class="flex items-center">
          <span style="font-size: 12px">{{ option.driver.udi }}</span>
          <span
            v-if="option.status == 'linked-non-ewd'"
            class="px-1 ml-1 font-normal text-white rounded"
            style="font-size: 7px; background-color: rgb(255, 153, 0)"
          >
            NON-EWD
          </span>
          <span
            v-if="option.status == 'delinked'"
            class="px-1 ml-1 font-normal text-white rounded"
            style="font-size: 7px; background-color: rgb(239, 68, 68)"
          >
            DELINKED
          </span>
        </div>
      </template>
      <span slot="noResult" class="text-xs">Oops! No driver found.</span>
      <span slot="noOptions" class="text-xs">Oops! No drivers found.</span>
      <template slot="selection" slot-scope="{ values }">
        <span class="multiselect__single"
          >{{ !isSelectAll ? values.length : driverCount }} drivers
          selected</span
        >
      </template>
      <template slot="beforeList">
        <li
          class="flex items-center px-3 py-3 cursor-pointer"
          @click="onSelectAll"
        >
          <input
            type="checkbox"
            v-model="isSelectAll"
            class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 rounded"
          />
          <span class="text-sm"
            >Select All (<strong>{{ paginationSettings.totalRecords }}</strong>
            Drivers)</span
          >
        </li>
      </template>
      <div
        v-if="!isLoading && (hasPrevPage || hasNextPage)"
        slot="afterList"
        class="flex pagination"
      >
        <button
          :disabled="!hasPrevPage"
          @click.prevent="onPrevPage"
          class="rounded-tl rounded-bl"
          :class="{ disabled: !hasPrevPage }"
        >
          Prev
        </button>
        <button
          :disabled="!hasNextPage"
          @click.prevent="onNextPage"
          class="rounded-tr rounded-br"
          :class="{ disabled: !hasNextPage }"
        >
          Next
        </button>
      </div>
    </multiselect>
  </div>
</template>
<script>
import { debounce, uniqWith } from "lodash";
import Multiselect from "vue-multiselect";
import { formatPaginationSettings } from "@/_helper";

const DriverMultiSelect = {
  name: "DriverMultiSelect",
  components: {
    Multiselect,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    hasPrevPage: false,
    hasNextPage: false,
    isSelectAll: false,
    isIncludeDelinked: false,
    unSelectedDrivers: [],
    selectedDrivers: [],
    drivers: [],
    value: [],
    filter: {
      limit: 10,
      status: "all",
      search: "",
    },
    paginationSettings: {
      page: 1,
      totalPages: 5,
      totalRecords: 50,
      visiblePageItemCount: 3,
    },
    driverCount: 0,
    me: null,
    allDrivers: [],
    debouncedOnSearch: () => {},
  }),
  created() {
    this.debouncedOnSearch = debounce(async (search) => {
      this.filter.search = search;
      this.drivers = [];
      this.isLoading = true;

      let filter = [{ status: "linked" }, { status: "linked-non-ewd" }];
      if (this.isIncludeDelinked) filter.push({ status: "delinked" });

      const query = {
        skip:
          this.paginationSettings.page * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: { $or: filter },
      };
      // Reads from businessId to get business site id, otherwise use main business id
      const businessId = this.me.businessId
        ? this.me.businessId
        : this.me.business?._id;
      const driverContract = await this.$store.dispatch(
        `business/getContractByBusiness`,
        { uid: businessId, query }
      );
      if (driverContract) {
        if (driverContract?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            driverContract?.metadata
          );
          this.hasNextPage =
            this.paginationSettings.page < this.paginationSettings.totalPages;
          this.hasPrevPage = this.paginationSettings.page > 1;
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        this.drivers = uniqWith(
          driverContract.results,
          (dataA, dataB) =>
            dataA.driverId === dataB.driverId &&
            dataA.businessId === dataB.businessId
        );
        if (this.isSelectAll) this.value = this.drivers;
      }
      this.isLoading = false;
    }, 500);
  },
  async mounted() {
    this.me = this.$store.getters[`account/me`];
    this.selectedDrivers = [];
    this.unSelectedDrivers = [];

    await this.getAllDriverIds();
    await this.onSearch();

    // Handles default selected drivers
    if (
      this.context.attributes.defaultSelectedDriverIds &&
      this.context.attributes.defaultSelectedDriverIds.length > 0
    ) {
      this.value = this.context.attributes.defaultSelectedDriverIds;
    }

    if (this.context.attributes?.isIncludeDelinked) {
      this.isIncludeDelinked = this.context.attributes?.isIncludeDelinked;
    }
  },
  methods: {
    async onSearch(search = "") {
      this.debouncedOnSearch(search);
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        this.paginationSettings.page = this.paginationSettings.page - 1;
        this.onSearch();
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
        this.paginationSettings.page = this.paginationSettings.page + 1;
        this.onSearch();
      }
    },
    onSelectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.value = this.isSelectAll ? this.drivers : [];
      this.selectedDrivers = [];
      this.unSelectedDrivers = [];

      if (this.isSelectAll) {
        this.selectedDrivers = [...this.drivers];
      } else {
        this.$emit("clear");
      }

      this.emitData();
    },
    onSelect(driver) {
      const unselectedIndex = this.unSelectedDrivers.findIndex(
        (o) => o.driverId === driver.driverId
      );
      if (unselectedIndex !== -1) {
        this.unSelectedDrivers.splice(unselectedIndex, 1);
      }

      this.selectedDrivers.push(driver);
      this.emitData();
    },
    onRemove(driver) {
      const selectedIndex = this.selectedDrivers.findIndex(
        (o) => o.driverId === driver.driverId
      );
      if (selectedIndex !== -1) {
        this.selectedDrivers.splice(selectedIndex, 1);
      }

      if (this.isSelectAll) {
        this.unSelectedDrivers.push(driver);
      }

      this.emitData();
    },
    emitData() {
      if (this.isSelectAll) {
        let dataParam = {};
        if (this.unSelectedDrivers.length > 0) {
          dataParam.except = this.unSelectedDrivers;
        }

        this.driverCount =
          this.paginationSettings.totalRecords - this.unSelectedDrivers.length;
        dataParam.driverCount = this.driverCount;
        this.$emit("selected", this.selectedDrivers, dataParam);
      } else {
        this.$emit("selected", this.selectedDrivers);
      }

      this.$emit("pagination", {
        ...this.paginationSettings,
        isSelectAll: this.isSelectAll,
      });
    },
    async getAllDriverIds() {
      const businessId = this.me.businessId
        ? this.me.businessId
        : this.me.business?._id;
      const drivers = await this.$store.dispatch(
        `business/getDriverIdsByBusinessId`,
        businessId
      );
      this.allDrivers = drivers;
    },
  },
};

export const VueFormulateDriverMultiSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      DriverMultiSelect,
    },
    library: {
      "driver-multiselect": {
        classification: "select",
        component: "DriverMultiSelect",
      },
    },
  });
};

export default DriverMultiSelect;
</script>
<style lang="scss" scoped>
.multiselect::v-deep {
  .multiselect__content-wrapper {
    overflow-x: hidden;
  }
  .multiselect__placeholder {
    display: none;
  }
  .multiselect__tags {
    min-height: 45px;
    padding: 12px 40px 0 8px;
    @apply border-gray-300;
  }
  .multiselect__input {
    @apply placeholder-gray-400;
  }
  .multiselect__input,
  .multiselect__single {
    font-size: 14px;
    font-weight: 500;
  }
  .multiselect__select {
    height: 43px;
  }
  .multiselect__option {
    @apply text-sm;
    padding-top: 5px;
    padding-bottom: 5px;

    &:after {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .pagination {
    @apply flex text-sm;
    margin: 0.25rem 0.25rem 0;

    button {
      flex-grow: 1;
      @apply text-gray-900 focus:outline-none bg-white border border-gray-400 hover:bg-gray-100;

      &.disabled {
        @apply text-gray-300 border-gray-200 bg-gray-50 hover:bg-gray-50 cursor-not-allowed;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
