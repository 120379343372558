import { API } from "@/api/config";
import { ObjectToQueryString } from "../../../_helper";

export default new (class Accreditation {
  getAllAccreditation(
    payload,
    pagination = { skip: 0, limit: 10, filter: {} }
  ) {
    let filter = "";
    if (typeof pagination.filter === "object") {
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }
    }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);

    pagination = "?" + ObjectToQueryString(pagination) + "&";

    return API.ACCOUNTS.get(
      `/certification/${pagination}isActive=true`,
      payload
    );
  }

  findOneCertification(id) {
    return API.ACCOUNTS.get(`certification/${id}`);
  }

  fetchCertificationById(id) {
    return API.ACCOUNTS.get(`/certification/${id}`);
  }

  fetchCertificationByCertNumber(id, query) {
    const filter = ObjectToQueryString(query.filter);

    return API.ACCOUNTS.get(`certification/cert-number/${id}?${filter}`);
  }

  submitAccreditation(payload) {
    return API.ACCOUNTS.post(`/certification`, payload);
  }

  assignDriver(payload, certId) {
    return API.ACCOUNTS.patch(`certification/${certId}/assign-driver`, payload);
  }

  unAssignDriver(payload, certId) {
    return API.ACCOUNTS.patch(
      `certification/${certId}/unassign-driver`,
      payload
    );
  }

  uploadAccreditationFile(file) {
    const { fileType, certId, file: fileBase64, mimetype, uuid } = file;

    let formData = new FormData();

    formData.append("certId", certId);
    formData.append("increaseVersion", false);
    formData.append("file", fileBase64);
    if (fileType == "adminSignature" || fileType == "templateImage") {
      formData.append("mimetype", mimetype);
      if (file?.uuid) formData.append("uuid", uuid);
    }
    return API.ACCOUNTS.post(`certification/upload/${fileType}`, formData);
  }

  async fetchDriversProfile(
    certId,
    pagination = { skip: 0, limit: 10, filter: {} },
    isPending
  ) {
    let filter = "";

    if (typeof pagination.filter === "object")
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);

    let isPendingString = `&isPending=${isPending}`;

    if (pagination) pagination = "?" + ObjectToQueryString(pagination);

    return API.ACCOUNTS.get(
      `certification/drivers-profile/${certId}${pagination}${isPendingString}`
    );
  }

  async fetchUserDocuments(
    id,
    pagination = { skip: 0, limit: 10, filter: {} }
  ) {
    let filter = "";

    if (typeof pagination.filter === "object")
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);

    if (pagination) pagination = "?" + ObjectToQueryString(pagination);

    return API.ACCOUNTS.get(`user-document/admin/user/${id}${pagination}`);
  }

  fetchAssociatedCertificates(
    id,
    status,
    pagination = { skip: 0, limit: 10, filter: {} }
  ) {
    let filter = "";
    if (typeof pagination.filter === "object")
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);

    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";

    return API.ACCOUNTS.get(
      `certification/driver/${id}${pagination}isActive=${status}`
    );
  }

  consentDriver(id, driverId) {
    return API.ACCOUNTS.patch(`certification/${id}/consent`, { driverId });
  } //ACCREDITATION CONSENT

  consentDriverUserDocument(docId, driverId) {
    return API.ACCOUNTS.patch(`user-document/${docId}/consent`, { driverId });
  } //USER DOCUMENT CONSENT

  checkConsentDriverUserDocument(docId, driverId) {
    return API.ACCOUNTS.get(
      `user-document/consent/doc-number/${docId}?driverId=${driverId}`
    );
  } //USER DOCUMENT CONSENT

  uploadUserDocument(file) {
    const promises = [];

    let medCertData = new FormData();
    let trainingData = new FormData();
    const signatureData = new FormData();

    if (file?.medCertPayload?.file) {
      medCertData.append("userMongoId", file.medCertPayload.userMongoId);
      medCertData.append("userType", file.medCertPayload.userType);
      medCertData.append("file", file.medCertPayload.file);
      if (file.medCertPayload.expiryDate) {
        medCertData.append("expiryDate", file.medCertPayload.expiryDate);
      }

      const medPromise = API.ACCOUNTS.post(
        `user-document/driver/upload/${file.medCertPayload.fileType}`,
        medCertData
      );
      promises.push(medPromise);
    }

    if (file?.trainingCertPayload?.file) {
      trainingData.append("userMongoId", file.trainingCertPayload.userMongoId);
      trainingData.append("userType", file.trainingCertPayload.userType);
      trainingData.append("file", file.trainingCertPayload.file);
      if (file.trainingCertPayload.expiryDate) {
        trainingData.append("expiryDate", file.trainingCertPayload.expiryDate);
      }

      const trainPromise = API.ACCOUNTS.post(
        `user-document/driver/upload/${file.trainingCertPayload.fileType}`,
        trainingData
      );
      promises.push(trainPromise);
    }

    if (file?.signaturePayload?.file) {
      if (file.signaturePayload.driverId) {
        signatureData.append("driverId", file.signaturePayload.driverId);
      }
      if (file.signaturePayload.userMongoId) {
        signatureData.append("userMongoId", file.signaturePayload.userMongoId);
      }
      if (file.signaturePayload.userType) {
        signatureData.append("userType", file.signaturePayload.userType);
      }
      signatureData.append("file", file.signaturePayload.file);
      signatureData.append("fileType", file.signaturePayload.fileType);
      signatureData.append("mimetype", file.signaturePayload.mimetype);
      signatureData.append("isBase64", file.signaturePayload.isBase64);
      const trainPromise = API.ACCOUNTS.post(
        `user-document/driver/upload/${file.signaturePayload.fileType}`,
        signatureData
      );
      promises.push(trainPromise);
    }

    return Promise.all(promises);
  }

  async fetchTemplates(pagination = { skip: 0, limit: 10, filter: {} }) {
    let filter = "";

    if (typeof pagination.filter === "object")
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }

    pagination.filter = filter;
    filter = JSON.stringify(pagination.filter);

    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";

    return API.ACCOUNTS.get(`/document-builder/find-all${pagination}`);
  }

  async fetchTemplatesByBusiness(
    pagination = { skip: 0, limit: 10, filter: {} },
    businessId
  ) {
    let filter = "";

    if (typeof pagination.filter === "object")
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }

    pagination.filter = filter;
    filter = JSON.stringify(pagination.filter);

    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";

    return API.ACCOUNTS.get(
      `/document-builder/${businessId}/find-all${pagination}`
    );
  }

  async reviewDriver(id, driverId, isApproved) {
    return API.ACCOUNTS.patch(`/certification/${id}/review-driver`, {
      driverId,
      isApproved,
    });
  }

  async createTemplate(sections, name, documentType) {
    return API.ACCOUNTS.post(`/document-builder`, {
      sections,
      name,
      documentType,
    });
  }

  async editCertTemplate(certId, templateId) {
    return API.ACCOUNTS.post(`certification/${certId}/edit-template`, {
      templateId,
    });
  }

  async regenerate(certId, userId) {
    return API.ACCOUNTS.post(`/certification/${certId}/regenerate-letter`, {
      userId,
    });
  }

  async fetchPreviewImages(signedUrl) {
    return API.ACCOUNTS.post(`/document-builder/fetch-image`, { signedUrl });
  }

  async activateTemplate(id) {
    return API.ACCOUNTS.post(`/document-builder/activate/${id}`);
  }

  async fetchOneTemplate(id) {
    return API.ACCOUNTS.get(`/document-builder/${id}`);
  }

  async updateCertTemplate(content, templateId) {
    return API.ACCOUNTS.patch(`/document-builder/${templateId}`, { content });
  }

  async isDefault(id, businessId) {
    return API.ACCOUNTS.get(`/document-builder/${id}/${businessId}/is-default`);
  }

  async setDefaultTemplate(templateId) {
    return API.ACCOUNTS.patch(`/document-builder/${templateId}/set-default`);
  }

  async resetDefault(businessId) {
    return API.ACCOUNTS.patch(`/document-builder/${businessId}/reset-default`);
  }

  async removeTemplate(certId) {
    return API.ACCOUNTS.post(`certification/${certId}/remove-template`);
  }

  async renewCertificate(id, payload) {
    let formData = new FormData();
    formData.append("file", payload.renewalFile);
    formData.append("businessId", payload.businessId);
    formData.append("expiryDate", payload.expiryDate);

    return API.ACCOUNTS.patch(`certification/${id}/renew`, formData);
  }

  async editCert(certId, payload) {
    return API.ACCOUNTS.patch(`certification/${certId}`, payload);
  }

  async updateActivity(certId) {
    return API.ACCOUNTS.patch(`user-document/${certId}/activity`);
  }

  async updateExpiry(docId, expiryDate) {
    return API.ACCOUNTS.patch(`user-document/${docId}/expiry`, { expiryDate });
  }
})();
