import { API } from '@/api/config'

export default new class FormBuilder {
    // Form
    createForm(data){
      return API.ACCOUNTS.post(`/form`, data);
    }

    simulateForm(data){
      return API.ACCOUNTS.post(`/form/simulate`, data);
    }

    getFormByType(data){
      return API.ACCOUNTS.post(`/form/find-by-form-type`, data);
    }

    updateFormStatus(data){
      return API.ACCOUNTS.post('/form/update-status', data);
    }

    updateForm(data){
      return API.ACCOUNTS.patch(`/form/${data._id}`, data)
    }

    getBusinessFormByFormId(id){ 
      return API.ACCOUNTS.get(`/form/${id}`)
    }

    cloneFormToSite(data){
      return API.ACCOUNTS.post(`/form/${data.id}/clone`, data)
    }

    getBusinessFormVersionsByParentId(id){
      return API.ACCOUNTS.get(`/form/find-versions-by-parent/${id}`)
    }

    acknowledgeForm(id, payload){
      return API.ACCOUNTS.patch(`/filled-form/${id}/acknowledge`, payload)
    }

    getFormSettingByBusinessId(id){
      return API.ACCOUNTS.get(`/business-form-setting/find-one-by-buinessId/${id}`)
    }

    updateBusinessFormSetting(data){
      return API.ACCOUNTS.patch(`/business-form-setting/update-form-setting-status/${data.id}`, data)
    }

    createSiteFormSetting(data){
      return API.ACCOUNTS.post(`/business-form-setting/create-site-form-setting`, data)
    }
    
    // Form Master
    createFormMaster(data){
      return API.ACCOUNTS.post(`/form-master`, data);
    }

    getFormMastersByType(type){
      return API.ACCOUNTS.get(`/form-master/find-by-form-type/${type}`);
    }

    getActiveFormMasterByType(type){
      return API.ACCOUNTS.get(`/form-master/find-active-by-form-type/${type}`);
    }

    getFormMastersByFormId(id){
      return API.ACCOUNTS.get(`/form-master/${id}`)
    }

    updateFormMasters(data){
      return API.ACCOUNTS.patch(`/form-master/${data._id}`, data)
    }

    // Template data
    getFormsDataByType(formType){
      return API.ACCOUNTS.get(`/template-data/find-by-type/${formType}`);
    }

    getFormDataByTemplateIdAndUserId(data){
      return API.ACCOUNTS.get(`/template-data/by-template-user/${data.templateId}/${data.userId}`);
    }

    // Contract Terms Form
    createContractTermForm(data){
      return API.ACCOUNTS.post(`/contract-term`, data);
    }

    getAllContractFormTerms(){
      return API.ACCOUNTS.get(`/contract-term`);
    }

    getContractTermByType(type){
      return API.ACCOUNTS.get(`/contract-term/find-by-type/${type}`);
    }

    getContractTermByFormId(id){
      return API.ACCOUNTS.get(`/contract-term/${id}`)
    }
    
    updateContractTermForm(data){
      return API.ACCOUNTS.patch(`/contract-term/${data._id}`, data)
    }
}