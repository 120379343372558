<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit">
      <FormulateInput
          type="email"
          name="emailAddress"
          label="Email Address"
          validation="required|email"
      />

      <FormulateInput
          type="places-search-input"
          name="driverBaseLocation"
          label="Base Location"
          validation="^required"
      />

      <FormulateInput
          type="places-search-input"
          name="driverRecordKeeperAddress"
          label="Record Keeper Address"
          validation="required"
      />

      <FormulateInput
          type="select"
          name="driverBaseTimeZone"
          label="Base Time Zone"
          :options="timezones"
          validation="required"
      />

      <FormulateInput
          type="select"
          name="region"
          label="Region"
          :options="regions"
          validation="required"
      />

      <FormulateInput
          type="text"
          name="driverBFMNumber"
          label="BFM Number if applicable"
      />


      <div class="items-center justify-between">
        <button
            type="submit"
            class="btn btn-primary w-full"
            :style="{ backgroundColor: theme.secondaryColor }"
        >
          Next
        </button>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { STATES, TIMEZONES } from '@/_helper/constants';
import { REGION } from "@/constants/region";
import { mapGetters } from "vuex";

export default {
  name: "Contractor-Details-Form",
  data() {
    return {
      states: STATES,
      timezones: TIMEZONES,
      regions: REGION,
      driver: {},
      values: {},
      errorMessage: '',
    };
  },
  methods: {
    onSubmit() {
      const driver = {
        emailAddress: this.values.emailAddress.toLowerCase(),
        driverBaseLocation: this.values.driverBaseLocation,
        driverRecordKeeperAddress: this.values.driverRecordKeeperAddress,
        driverBaseTimeZone: this.values.driverBaseTimeZone,
        region: this.values.region,
        driverBFMNumber: this.values.driverBFMNumber,
        status: 'pending',
        checked: false,
        disabled: false
      }

      this.$emit("nextStep", { drivers: [driver] });
    }
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
