// import { findRootUID } from '@/_helper';
import { Notification } from "@/api";

export default {
  async getAllUnAcknowledgedNotifications() {
    try {
      const response = await Notification.getAllUnAcknowledgedNotifications();
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getAllNotifications(query = null) {
    try {
      const response = await Notification.getAllNotifications(query);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getNotificationByID({ state }, id) {
    if (id) {
      if (!id) throw "Invalid uid " + state.me;
    }
    try {
      const response = await Notification.getNotificationByID(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateItemStatus({ state }, data) {
    try {
      const response = await Notification.updateItemStatus(data);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },

  async toggleNotification({ state }, data) {
    try {
      const { type, checked, webProfileUid } = data;
      const response = await Notification.toggleNotification(
        type,
        checked,
        webProfileUid
      );
      return response;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
};
