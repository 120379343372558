<template>
  <div class="mb-16">

    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>

    <FormulateForm
        v-if="!loading"
        @submit="onSubmit"
    >
      <div v-for="vehicle in vehicles" :key="vehicle.id">
        <div class="flex items-center gap-16">

          <FormulateInput
              type="text"
              label="Vehicle Plate"
              validation="required"
              :disabled="true"
              v-model="vehicle.vehiclePlate"
          />

          <FormulateInput
              :options="{ true: 'Active', false: 'Inactive' }"
              type="radio"
              label="Active Status"
              class="custom-options"
              v-model="vehicle.isActive"
          />

        </div>
      </div>

      <StyledSubmit :backgroundColor="theme.secondaryColor">
        <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
        />
      </StyledSubmit>

    </FormulateForm>

    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>

  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";

export default {
  name: "Manage-Vehicles",
  components: { StyledSubmit },
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      values: {},
      vehicles: [],
      activeOptions: [

      ],
      hasErrors: false,
      errorMessage: "",
    };
  },

  async mounted() {
    this.loading = true;

    const query = {
      skip: 0,
      limit: -1,
      filter: {},
    };

    const vehicles = await this.$store.dispatch(
        `vehicle/getVehicleByEntityId`,
        {
          entityId: this.detailsId,
          query,
        }
    );

    if (vehicles.results && vehicles.results.length > 0) {
      this.vehicles = this.formatVehiclesData(vehicles.results);
    }

    this.loading = false;
  },

  methods: {

    async onSubmit() {
      this.loading = true;

      const payload = {
        entityId: this.detailsId,
        vehicles: this.vehicles,
      };

      try {
        await this.$store.dispatch(
            `vehicle/updateMultipleVehicleStatus`,
            payload
        );
        this.loading = false;
        this.$emit("closeModal");
        this.toast("success", "Vehicles updated.");
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.errorMessage = "Something Went Wrong";
        this.toast("error", this.errorMessage);
      }

    },

    formatVehiclesData(items) {
      const nItems = [];
      if (items) {
        items.map((item) => {
          const vehicle = {
            id: item._id,
            vehiclePlate: item?.vehiclePlate,
            isActive: item?.isActive?.toString() || 'false',
          };
          nItems.push(vehicle);
        });
      }

      return nItems;
    },

    toast(state, message) {
      this.sendReportloading = false;
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },

  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
