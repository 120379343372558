<template>
  <div
    :data-type="context.type"
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
  >
    <list-select
      ref="selectRef"
      :options="options"
      :context="context"
      :isLoading="isLoading"
      :hasPrevPage="hasPrevPage"
      :hasNextPage="hasNextPage"
      noResultText="No drivers found."
      v-bind="omit(context.attributes, 'class')"
      :class="{ 'multiple-selection': 'multiple' in context.attributes }"
      @prevPage="onPrevPage"
      @nextPage="onNextPage"
      @search="onSearch"
    >
      <template slot="option" slot-scope="option">
        <div class="text-sm">
          <h3 class="m-0 font-semibold">{{ option.persona.businessName }}</h3>
          <em class="text-xs">{{ option.persona.abn }}</em>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected text-sm">
          {{ option.persona.businessName }}
        </div>
      </template>
    </list-select>
  </div>
</template>
<script>
import { debounce, uniqWith } from "lodash";
import { formatPaginationSettings } from "@/_helper";
import ListSelect from "@/components/commons/ui/list-select/ListSelect";

const PartnerBusinessSelect = {
  name: "PartnerBusinessSelect",
  components: {
    ListSelect,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    businesses: [],
    options: [],
    isLoading: false,
    hasPrevPage: false,
    hasNextPage: false,
    hasCheckbox: false,
    filter: {
      limit: 10,
      status: "all",
      search: "",
    },
    paginationSettings: {
      page: 1,
      totalPages: 5,
      totalRecords: 50,
      visiblePageItemCount: 3,
    },
    selectedPartner: {},
    debouncedOnSearch: () => {},
  }),
  created() {
    this.debouncedOnSearch = debounce(async (search) => {
      this.filter.search = search;
      this.options = [];
      this.isLoading = true;

      const query = {
        skip:
          this.paginationSettings.page * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: { $or: [{ status: "linked" }, { status: "linked-non-ewd" }] },
      };
      const partnerId = this.selectedPartner._id;
      const partnerBusiness = await this.$store.dispatch(
        `partner/getPartnerBusinessesByID`,
        { id: partnerId, query }
      );
      if (partnerBusiness) {
        if (partnerBusiness?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            partnerBusiness?.metadata
          );
          this.hasNextPage =
            this.paginationSettings.page < this.paginationSettings.totalPages;
          this.hasPrevPage = this.paginationSettings.page > 1;
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        this.businesses = uniqWith(
          partnerBusiness.results,
          (dataA, dataB) =>
            dataA._id === dataB._id && dataA.entityId === dataB.entityId
        );
        this.options = this.businesses.map((business) => {
          business.isActive = true;
          business.label = business.persona.businessName;
          return business;
        });
      }
      this.isLoading = false;
    }, 500);
  },
  mounted() {
    if (this.context.attributes?.hasCheckbox)
      this.hasCheckbox = this.context.attributes.hasCheckbox;
    this.selectedPartner = this.context.slotProps?.component?.selectedPartner;
    this.$refs.selectRef.onSearch();
  },
  methods: {
    omit(obj, keys) {
      let result = {};
      for (const [key, value] of Object.entries(obj)) {
        if (!keys.includes(key)) {
          result[key] = value;
        }
      }
      return result;
    },
    async onSearch(search = "") {
      this.debouncedOnSearch(search);
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        this.paginationSettings.page = this.paginationSettings.page - 1;
        this.onSearch();
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
        this.paginationSettings.page = this.paginationSettings.page + 1;
        this.onSearch();
      }
    },
  },
};

export const VueFormulatePartnerBusinessSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      PartnerBusinessSelect,
    },
    library: {
      "partner-business-select": {
        classification: "select",
        component: "PartnerBusinessSelect",
        slotProps: {
          component: ["selectedPartner"],
        },
      },
    },
  });
};

export default PartnerBusinessSelect;
</script>
<style lang="scss" scoped>
.multiple-selection::v-deep {
  .vs__dropdown-toggle {
    @apply overflow-hidden;

    .vs__selected-options {
      @apply flex-nowrap;
    }
  }

  .vs__dropdown-menu .vs__dropdown-option {
    @apply px-2;
  }
}
</style>
