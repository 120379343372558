import { Form } from "@/api"
import _ from 'lodash'

export default{
    // Form
    async createForm({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me;
        try{
            const response = await Form.createForm(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },


    async getFormByType( { state }, type ){
        if(!type) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.getFormByType(type);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async updateBusinessForm({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me;
        try{
            const response = await Form.updateBusinessForm(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async updateFormStatus({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me;
        try{
            const response = await Form.updateFormStatus(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async cloneFormToSite({ state }, data){
        if(!data.id) throw 'Invalid id '+state.me;
        try{
            const response = await Form.cloneFormToSite(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getBusinessFormByFormId( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Form.getBusinessFormByFormId(id);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getFormSettingByBusinessId( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Form.getFormSettingByBusinessId(id);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async updateBusinessFormSetting( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Form.updateBusinessFormSetting(data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async createSiteFormSetting( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Form.createSiteFormSetting(data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async acknowledgeForm( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        const {formId} = data;
        delete data.formId;
        try{
            const response = await Form.acknowledgeForm(formId, data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },


    // Form Master
    async createFormMaster({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me;
        try{
            const response = await Form.createFormMaster(data)
            return response.data.data
        }catch(error){
            throw error.response
        }
    },


    async createDefaultForm({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me;
        try{
            const response = await Form.createDefaultForm(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getDefaultFormByFormId( { state }, type ){
        if(!type) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.getDefaultFormByFormId(type);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getFormMastersByType( { state }, type ){
        if(!type) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.getFormMastersByType(type);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getActiveFormMasterByType( { state }, type ){
        if(!type) throw 'Invalid type '+state.me;
        try{
            const response = await Form.getActiveFormMasterByType(type);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async updateDefaultForm( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me;
        try{
            const response = await Form.updateDefaultForm(data);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    // Template Data
    async createContractTermForm( { state }, data ){
        if(!data) throw 'Invalid form type '+state.me;
        try{
            const response = await Form.createContractTermForm(data);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getAllContractFormTerms( { state }, data ){
        if(!data) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.getAllContractFormTerms(data);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getContractTermByType( { state }, type ){
        if(!type) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.getContractTermByType(type);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getContractTermByFormId( { state }, data ){
        if(!data) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.getContractTermByFormId(data);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async updateContractTermForm( { state }, data ){
        if(!data) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.updateContractTermForm(data);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getFormDataByTemplateIdAndUserId( { state }, data ){
        if(!data) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.getFormDataByTemplateIdAndUserId(data);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getBusinessFormVersionsByParentId( { state }, uid ){
        if(!uid) throw 'Invalid uid '+state.me;
        try{
            const response = await Form.getBusinessFormVersionsByParentId(uid);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getFormSection({ state }, uniqueId) {
        if(!uniqueId) throw 'Invalid data'
        try {
            const formSections = state.formSections
            const response = await formSections.find(obj => obj.uniqueId == uniqueId)
            return response
        } catch(error) {
            throw error.response.error
        }
    },
    
    async clearForm({ state }) {
        state.selectedControl = {}
        state.selectedSection = {}
        state.selectedControlSection = {}
        state.formControls = []
        state.formSections = []
        state.updatedControl = {}
    },
    
    // Both
    async simulateForm({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me;
        try{
            const response = await Form.simulateForm(data)
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async getFormsDataByType({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me;
        try{
            const response = await Form.getFormsDataByType(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    }
}